import { useMainContext } from 'context/MainContext';
import React, { useCallback } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Container } from './styles';

const Dialog: React.FC = () => {
  const { dialog, setDialog } = useMainContext();

  const handleClose = useCallback(() => {
    if (dialog.callBackCancel) {
      dialog.callBackCancel();
      setDialog({ show: false });
    } else {
      setDialog({ show: false });
    }
  }, [setDialog, dialog]);

  const handleShow = useCallback(() => {
    if (dialog.callBackConfirm) {
      dialog.callBackConfirm();
      setDialog({ show: false });
    }
  }, [dialog, setDialog]);

  const handleClassHeader = useCallback(() => {
    if (dialog.isError) return 'alert-danger';
    if (!dialog.isError && dialog.isAlert) return 'alert-success';
    return 'alert-secondary';
  }, [dialog.isAlert, dialog.isError]);

  if (!dialog.show) return null;
  return (
    <Container show={dialog.show} onHide={handleClose} centered>
      <Modal.Header closeButton className={handleClassHeader()}>
        <h2 className={handleClassHeader()}>
          {dialog.textTitle || 'Confirmar Operação?'}
        </h2>
      </Modal.Header>

      {(dialog.textBody ||
        (dialog.textError && dialog.textError?.length > 0)) && (
        <Modal.Body className="modal-body-max-height">
          {dialog.isError &&
          dialog.textError &&
          dialog.textError?.length > 0 ? (
            <div>
              {dialog.textError?.map((text, index) => (
                <p key={index.toString()}>{text}</p>
              ))}
            </div>
          ) : (
            <p>{dialog.textBody}</p>
          )}
        </Modal.Body>
      )}
      {!dialog.isAlert && (
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>

          <Button variant="primary" onClick={handleShow}>
            Confirmar
          </Button>
        </Modal.Footer>
      )}
    </Container>
  );
};

export default Dialog;
