import styled from 'styled-components';

export const Container = styled.div`
  tr:hover {
    .right-arrow {
      color: var(--dark-grey);
      height: 22px;
      width: 22px;
    }
  }
`;
