import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled.div``;

export const CenteredColumn = styled(Col)`
  display: flex;
  justify-content: center;
  padding: 20px;
`;
