import { Edit } from '@styled-icons/feather';
import styled from 'styled-components';

export const Container = styled.div``;
export const LabelText = styled.h5`
  color: #808080;
`;
export const DescripText = styled.h6`
  color: #80808080;
`;
export const NumberOrders = styled.h2`
  color: #ff4f28;
  font-size: calc(var(--font-size) * 6.25);
`;
export const EditButton = styled(Edit)`
  color: var(--primary);
  width: 30px;
  height: 30px;
  cursor: pointer;
`;
