import { IRule } from 'interfaces/IRule';
import React from 'react';
import { Container } from './styles';

const PopoverBodyRules: React.FC<{
  rules: IRule[];
}> = ({ rules }) => (
  <Container>
    {rules.map(r => (
      <p className="break-word" key={r.Id}>
        {r.Description}
      </p>
    ))}
  </Container>
);

export default PopoverBodyRules;
