import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div``;
export const ButtonActions = styled(Link)`
  color: inherit;
  &:hover {
    color: inherit;
  }
  svg:hover {
    transform: scale(1.1);
  }
  cursor: pointer;
`;

export const CustomInput = styled.input`
  display: block;
  width: 95%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-inline: 2%;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;
