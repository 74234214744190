import styled from 'styled-components';
import { Exit } from 'styled-icons/icomoon';

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
  width: 100%;

  &.menu span,
  &.menu svg {
    color: var(--dark-grey) !important;

    fill: var(--dark-grey) !important;

    path {
      color: var(--dark-grey) !important;
    }
  }

  &:hover {
    transform: scale(1.03);
  }
  span {
    color: var(--white);
    margin-left: 5px;
  }
`;

export const LogoutICon = styled(Exit)`
  fill: var(--white) !important;

  path {
    color: var(--white) !important;
  }
  width: 25px;
`;
