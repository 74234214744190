import { IOrder } from 'interfaces/IOrder';
import React from 'react';
import { Table } from 'react-bootstrap';

interface IProps {
  order: IOrder;
  orders: IOrder[] | null;
}
const PopoverBodyOrder: React.FC<IProps> = ({ order, orders }) => (
  <Table className=" thead-transparent" borderless>
    <thead>
      <tr className="height-small">
        <th>Itens</th>
        <th>Qtde</th>
        <th>Valor</th>
      </tr>
    </thead>
    <tbody>
      {orders
        ?.filter(x => x.ProductContractUpperId === order.Id)
        .map(y => (
          <tr key={y.Id.toString()} style={{ lineHeight: 'normal' }}>
            <td>{y.ProductName}</td>
            <td>{y.Quantity}</td>
            <td>
              {y.Price.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </td>
          </tr>
        ))}
    </tbody>
  </Table>
);

export default PopoverBodyOrder;
