/* eslint-disable prettier/prettier */
/* eslint-disable no-extend-native */
declare global {
  interface Date {
    addMonths: ( amount: number)=>Date;
    toYearMonth:()=> string;
  }
  interface String {
    toDate: ()=>Date;
  }
}

function monthDiff(from: Date, to: Date) {
  const years = to.getFullYear() - from.getFullYear();
  const months = to.getMonth() - from.getMonth();
  return 12 * years + months;
}

Date.prototype.addMonths = function ( amount: number): Date {
  const endDate = new Date(this.getTime());
  const originalTimeZoneOffset = endDate.getTimezoneOffset();
  endDate.setMonth(endDate.getMonth() + amount);
  while (monthDiff(this, endDate) > amount) {
    endDate.setDate(endDate.getDate() - 1);
  }
  const endTimeZoneOffset = endDate.getTimezoneOffset();
  const diff = endTimeZoneOffset - originalTimeZoneOffset;
  const finalDate = diff
    ? endDate.setMinutes(endDate.getMinutes() - diff)
    : endDate;
  return new Date(finalDate);
};
Date.prototype.toYearMonth = function (): string {
  const month = `0${this.getMonth()+1}`;
  const monthFmt= month.substring(month.length - 2);

  return `${this.getFullYear()}-${monthFmt}`;
};

String.prototype.toDate = function (): Date {
  const dateSplit = this.split('-').map(x=>Number.parseInt(x,10));
  return  new Date(dateSplit[0], dateSplit[1]-1, dateSplit[2]);
};

export { };

