import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InfoSquareFill } from 'styled-icons/bootstrap';

type Props = {
  description: string;
};

const TableTooltips = (props: Props) => {
  const { description } = props;

  return (
    <OverlayTrigger
      trigger={['click', 'hover']}
      overlay={
        <Tooltip>
          <p>{description}</p>
        </Tooltip>
      }
    >
      <InfoSquareFill cursor="pointer" size={18} />
    </OverlayTrigger>
  );
};

export default TableTooltips;
