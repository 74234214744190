import { LabelCustom } from 'components/LabelCustom';
import { IOrderBilling, IOrderBillingData } from 'interfaces/IOrder';
import React from 'react';
import { Table } from 'react-bootstrap';
import { Container } from './styles';

const TableBillings: React.FC<{ data: IOrderBillingData }> = ({ data }) => (
  <Container>
    <Table className="text-center thead-transparent" borderless>
      <thead>
        <tr>
          <th>Itens</th>
          <th>Qtde</th>
          <th>Valor mês</th>
        </tr>
      </thead>
      <tbody>
        {data.Billings &&
          data.Billings?.map((order: IOrderBilling) => (
            <tr key={order.Id}>
              <td>
                <LabelCustom>{order.ProductName}</LabelCustom>
              </td>
              <td>
                <LabelCustom> {order.Quantity}</LabelCustom>
              </td>
              <td>
                <LabelCustom>
                  {order.SubTotal.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </LabelCustom>
              </td>
            </tr>
          ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={2}>
            <b className="float-right">Total</b>
          </td>
          <td>
            <b>
              R${' '}
              {data &&
                data.Total.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
            </b>
          </td>
        </tr>
      </tfoot>
    </Table>
  </Container>
);

export default TableBillings;
