import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as S from './styles';

const BackButton: React.FC = () => {
  const navigate = useNavigate();

  return (
    <S.Container title="Voltar" onClick={() => navigate(-1)}>
      <S.IconBack /> Voltar
    </S.Container>
  );
};

export default BackButton;
