import { useMainContext } from 'context/MainContext';
import { IAuthUser, IRefreshToken } from 'interfaces/IAuthUser';
import { IResponse } from 'interfaces/IResponse';
import { IUserProfile } from 'interfaces/IUserProfile';
import { useCallback, useMemo } from 'react';
import api from './api';

export interface IResponseAuth extends IResponse {
  result: IAuthUser;
}

export const useAuthService = () => {
  const { setLoading, setDialog } = useMainContext();

  const getRefreshToken = useCallback(async (): Promise<IRefreshToken> => {
    const refreshTokenSession = sessionStorage.getItem('refresh-token');

    const config = refreshTokenSession
      ? {
          headers: {
            'refresh-token': refreshTokenSession
          }
        }
      : {};

    try {
      const {
        data: {
          result: { token, user, refreshToken, expiresIn },
          success,
          errors
        }
      } = await api.get<IResponseAuth>('Auth/RefreshToken', config);

      if (success) {
        api.defaults.headers.common.Authorization = `Bearer ${token}`;
        sessionStorage.setItem('refresh-token', refreshToken);

        const userJson = JSON.parse(
          sessionStorage.getItem('user') || '{}'
        ) as IUserProfile;

        const userResult = {
          ...user,
          Name: userJson?.Name
        } as IUserProfile;

        const time = (expiresIn || 15) * 60000;
        setTimeout(() => {
          getRefreshToken();
        }, time - 300000);

        return { user: userResult, expiresIn };
      }

      sessionStorage.clear();

      throw errors;
    } catch (error) {
      sessionStorage.clear();
      console.log(
        `error in useAuthService - function getRefreshToken, error:${error}`
      );
      throw error;
    }
  }, []);

  const login = useCallback(
    async (userRequest: IUserProfile): Promise<IUserProfile> => {
      setLoading(true);
      const userToSend = { ...userRequest };
      try {
        const { data: dataResponse } = await api.post<IResponseAuth>(
          'Auth/Login',
          userToSend
        );

        const {
          result: { token, user, refreshToken, expiresIn },
          success
        } = dataResponse;

        if (success) {
          api.defaults.headers.common.Authorization = `Bearer ${token}`;

          sessionStorage.setItem('refresh-token', refreshToken);
          sessionStorage.setItem(
            'user',
            JSON.stringify({ Name: user.Name } as IUserProfile)
          );

          const time = (expiresIn || 15) * 60000;
          setTimeout(() => {
            getRefreshToken();
          }, time - 300000);
        }
        return user;
      } catch (error) {
        console.log(`error in useAuthService - function Login, error:${error}`);
        setDialog({
          show: true,
          isAlert: true,
          isError: true,
          textTitle: 'Aconteu um erro inesperado',
          textBody: `error in AuthService - Login: ${JSON.stringify(error)}`
        });

        throw error;
      } finally {
        setLoading(false);
      }
    },
    [getRefreshToken, setDialog, setLoading]
  );

  const loginMocha = useCallback(
    async (userRequest: IUserProfile): Promise<IUserProfile> => {
      setLoading(true);
      sessionStorage.setItem('refresh-token', 'tokenteste');

      const newUser: IUserProfile = {
        Id: 'e1fdf0c6-9a0a-4819-9c3a-0979cb2ec487',
        MicrosoftId: 'af1aae8e-8327-4a01-985f-6c0760ee5d1c',
        Name: userRequest.Name,
        Email: userRequest.Email,
        IsAdm: true
      };

      sessionStorage.setItem('UserProfile', JSON.stringify(newUser));
      return new Promise<IUserProfile>(resolve => {
        setLoading(false);
        resolve(newUser);
      });
    },
    [setLoading]
  );

  const getRefreshTokenMocha = useCallback(async (): Promise<IRefreshToken> => {
    setLoading(true);
    const userResult = JSON.parse(
      sessionStorage.getItem('UserProfile') || ''
    ) as IUserProfile;
    return new Promise<IRefreshToken>(resolve => {
      setLoading(false);
      resolve({ user: userResult, expiresIn: 15 });
    });
  }, [setLoading]);

  return useMemo(
    () => ({
      authService: { getRefreshToken, login, loginMocha, getRefreshTokenMocha }
    }),
    [getRefreshToken, getRefreshTokenMocha, login, loginMocha]
  );
};
