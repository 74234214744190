import { useMainContext } from 'context/MainContext';
import React from 'react';
import * as S from './styles';

const Profile: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className
}) => {
  const { profile } = useMainContext();
  return (
    <S.Container className={className}>
      <S.WrapperInfo className={className}>
        <S.Name>{profile?.Name}</S.Name>
        <S.Email>{profile?.Email}</S.Email>
      </S.WrapperInfo>
      {/* <S.WrapperAvatar className={className}>
        <S.IconAvatar src={profile?.ImageUrl || imgProfileDefault} />
      </S.WrapperAvatar> */}
    </S.Container>
  );
};

export default Profile;
