import styled from 'styled-components';

export const Container = styled.div<{ show?: boolean }>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  position: fixed;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  /* background-color: black;
  opacity: 0.5; */
  background: var(--gradient-primary);

  z-index: 99;
  > .spinner {
    margin-right: 5px;
  }
`;
