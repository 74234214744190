import FilterText from 'components/FilterText';
import { IRule } from 'interfaces/IRule';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRuleService } from 'services/ruleService';
import { Edit } from 'styled-icons/material';
import * as S from './style';

const TableRules: React.FC = () => {
  const navigate = useNavigate();
  const [rules, setRules] = useState<IRule[] | null>(null);
  const { ruleService } = useRuleService();

  const getData = useCallback(async () => {
    try {
      const resultRules = await ruleService.getAll();
      setRules(resultRules);
    } catch (error) {
      console.log(`error in TableRules - function getData, error:${error}`);
    }
  }, [ruleService]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <S.Container>
      <FilterText
        placeholderInput="Descrição"
        nameFieldToFilter="Description"
        dataToFilter={rules}
        setDataToFilter={setRules}
      />
      {rules && rules.length > 0 && (
        <Row>
          <Col>
            <div className="table-thead-fixed">
              <Table className="table-striped text-center" responsive>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Descrição</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {rules.map((rule: IRule) => (
                    <tr key={rule.Id}>
                      <td>{rule.Id}</td>
                      <td>{rule.Description} </td>
                      <td>
                        <Button
                          onClick={() =>
                            navigate('AddEditRule', { state: rule })
                          }
                          variant="outline-primary"
                        >
                          <Edit title="Editar" size={22} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      )}
    </S.Container>
  );
};

export default TableRules;
