import { IGroupRule } from 'interfaces/IGroupRule';
import { useCallback, useMemo } from 'react';
import { useFetch } from './useFetch';

export const useGroupRuleService = () => {
  const { callApi } = useFetch();

  const getAll = useCallback(async () => {
    const groups = await callApi<IGroupRule[]>({
      url: 'GroupRule/GetAll',
      method: 'get',
      prefixMsgError: 'error in useGroupRuleService - getAll:'
    });

    return groups;
  }, [callApi]);

  const get = useCallback(
    async (id: string) => {
      const groupRule = await callApi<IGroupRule>({
        url: `GroupRule/Get/${id}`,
        method: 'get',
        prefixMsgError: 'error in useGroupRuleService - get:'
      });

      return groupRule;
    },
    [callApi]
  );

  const add = useCallback(
    async (groupRule: IGroupRule) => {
      await callApi({
        url: 'GroupRule/Add',
        method: 'post',
        data: groupRule,
        prefixMsgError: 'error in useGroupRuleService - add:'
      });
    },
    [callApi]
  );

  const update = useCallback(
    async (groupRule: IGroupRule) => {
      await callApi({
        url: `GroupRule/Update/${groupRule.Id}`,
        method: 'put',
        data: groupRule,
        prefixMsgError: 'error in useGroupRuleService - update:'
      });
    },
    [callApi]
  );

  return useMemo(
    () => ({
      groupRuleService: { get, getAll, add, update }
    }),
    [get, getAll, add, update]
  );
};
