import Title from 'components/Title';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { UserPlus } from 'styled-icons/fa-solid';
import TableUsers from './components/TableUsers';
import { Container } from './styles';

const Users: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Title>Usuários</Title>
      <Row>
        <Col xs={12} sm={6}>
          <Button
            variant="outline-primary"
            title="Adicionar Usuário"
            onClick={() => navigate('addEditUser')}
          >
            <UserPlus size="20" />
          </Button>
        </Col>
      </Row>
      <br />
      <TableUsers />
    </Container>
  );
};

export default Users;
