import Title from 'components/Title';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CollectionsAdd } from 'styled-icons/fluentui-system-filled';
import TableGroupRules from './components/TableGroupRules';
import { Container } from './styles';

const GroupRules: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Title>Grupo de Regras</Title>
      <Row>
        <Col xs={12} sm={6}>
          <Button
            title="Criar Grupo"
            variant="outline-primary"
            onClick={() => navigate('addEditGroupRules')}
          >
            <CollectionsAdd size="20" />
          </Button>
        </Col>
      </Row>
      <br />
      <TableGroupRules />
    </Container>
  );
};

export default GroupRules;
