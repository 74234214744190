import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from 'routes';
import 'utils/DateExtension';

const App: React.FC = () => (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <AppRoutes />
  </BrowserRouter>
);

export default App;
