import FilterText from 'components/FilterText';
import { enumStatus } from 'enum/enumStatus';
import { IUser } from 'interfaces/IUser';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Col,
  OverlayTrigger,
  Popover,
  Row,
  Table
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useUserService } from 'services/userService';
import { UserAltSlash, Users } from 'styled-icons/fa-solid';
import { Edit, VerifiedUser } from 'styled-icons/material';
import PopoverBodyGroupRules from '../PopoverBodyGroupRules';
import * as S from './style';

const TableUsers: React.FC = () => {
  const [users, setUsers] = useState<IUser[] | null>(null);
  const { userService } = useUserService();
  const navigate = useNavigate();

  const getData = useCallback(async () => {
    try {
      const resultUsers = await userService.getAll();
      setUsers(resultUsers);
    } catch (error) {
      console.log(`error in TableUsers - function getData, error:${error}`);
    }
  }, [userService]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <S.Container>
      <FilterText
        placeholderInput="Nome usuário"
        nameFieldToFilter="Name"
        dataToFilter={users}
        setDataToFilter={setUsers}
      />
      {users && users.length > 0 && (
        <Row>
          <Col>
            <div className="table-thead-fixed">
              <Table className="table-striped text-center" responsive>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Nome</th>
                    <th>E-mail</th>
                    <th>Dt. Criação</th>
                    <th>Admin</th>
                    <th>Status</th>
                    <th>Grupo</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user: IUser) => (
                    <tr key={user.Id}>
                      <td>
                        <span title={user.Id} className="span-ellipsis ">
                          {user.Id}
                        </span>
                      </td>
                      <td>{user.Name}</td>
                      <td>{user.Email}</td>
                      <td>{user.CreationDateFmt}</td>
                      <td>{user.IsAdm ? 'Sim' : 'Não'}</td>
                      <td>
                        {user.Status === enumStatus.Active ? (
                          <VerifiedUser
                            size="20"
                            color="var(--color-success)"
                          />
                        ) : (
                          <UserAltSlash size="20" />
                        )}
                      </td>
                      <td>
                        {!user.IsAdm && user.AdminGroupRules.length > 0 ? (
                          <OverlayTrigger
                            key={user.Id}
                            placement="top"
                            trigger={['click', 'hover']}
                            overlay={
                              <Popover>
                                <Popover.Header>
                                  Grupos Associado
                                </Popover.Header>
                                <Popover.Body>
                                  <PopoverBodyGroupRules
                                    groupRules={user.AdminGroupRules}
                                  />
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <Users size="24" color="var(--primary)" />
                          </OverlayTrigger>
                        ) : (
                          '-'
                        )}
                      </td>
                      <td>
                        <Button
                          type="button"
                          variant="outline-primary"
                          onClick={() =>
                            navigate('addEditUser', { state: user })
                          }
                          title="Editar"
                        >
                          <Edit size={20} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      )}
    </S.Container>
  );
};

export default TableUsers;
