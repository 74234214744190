import { Add } from '@styled-icons/fluentui-system-regular';
import Allow from 'components/Allow';
import Title from 'components/Title';
import { enumRules } from 'enum/enumRules';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TableProductTypes from './Components/TableProductTypes';
import { Container } from './styles';

export const ProductType: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Title>Tipos de Produtos</Title>
      <Row>
        <Allow ruleId={enumRules.ProductType.CanWriteProductType}>
          <Col xs={12} sm={6}>
            <Button
              variant="outline-primary"
              title="Adicionar Produto"
              onClick={() => navigate('addEditProductType')}
            >
              <Add size="20" />
            </Button>
          </Col>
        </Allow>
      </Row>
      <br />
      <TableProductTypes />
    </Container>
  );
};
