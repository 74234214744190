import axios, { AxiosError } from 'axios';

interface IError {
  errors: any;
}
const api = axios.create({
  baseURL: process.env.REACT_APP_REST_API_BASE_NAME,
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
    'Content-Type': 'application/json'
  }
});

api.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    if (error.response && error.response.status === 400) {
      return Promise.reject(error);
    }

    const errorResult: string[] = [];
    if (error?.response?.data?.errors) {
      const { errors } = error.response?.data as IError;
      Object.getOwnPropertyNames(errors).forEach((prop, index, itemProp) => {
        const value = errors[prop];
        const propDescription = itemProp.toString();
        if (Array.isArray(value)) {
          value.forEach(itemValue =>
            errorResult.push(`${propDescription} : ${itemValue}`)
          );
        } else if (typeof value === 'string') {
          value.split('at ').forEach(item => errorResult.push(item));
        }
      });
    } else if (error?.response?.data) {
      errorResult.push(error.response?.data);
    } else {
      const { code, message, stack } = error;
      if (code) errorResult.push(`Código do Erro: ${code}`);
      if (message) errorResult.push(`Mensagem de erro: ${message}`);
      if (stack) errorResult.push(`---Stack-error: ${stack}`);
    }
    return Promise.reject(errorResult);
  }
);

export default api;
