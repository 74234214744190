import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;

  &.menu {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
  }
`;

export const WrapperAvatar = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  &.menu {
    padding-bottom: 15px;
  }
`;
export const IconAvatar = styled.img`
  height: 35px;
  width: 35px;
  margin: 0 15px;
  &.menu {
    margin: 13px 15px;
  }
  border-radius: 50%;
  display: flex;
  align-items: center;
`;

export const WrapperInfo = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  &.menu span {
    text-align: center;
    color: var(--dark-gray);
  }
`;

const colorText = css`
  color: var(--white);
`;

export const Name = styled.span`
  ${colorText}
  font-size: calc(var(--font-size) * 0.75);
  display: inline-block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const Email = styled.span`
  ${colorText}
  font-size: calc(var(--font-size) * 0.6);
  display: inline-block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
