import { useMainContext } from 'context/MainContext';
import React from 'react';

const Allow: React.FC<{ ruleId: string }> = ({ ruleId, children }) => {
  const { profile } = useMainContext();
  if (profile?.Rules?.includes(ruleId) || profile?.IsAdm)
    return children as React.ReactElement;
  return null;
};

export default Allow;
