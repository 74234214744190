import FilterText from 'components/FilterText';
import Title from 'components/Title';
import { useMainContext } from 'context/MainContext';
import { IGroupRule } from 'interfaces/IGroupRule';
import { IRule } from 'interfaces/IRule';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useGroupRuleService } from 'services/groupRuleService';
import { useRuleService } from 'services/ruleService';
import { Container } from './styles';

const AddEditGroupRules: React.FC = () => {
  const groupId = useLocation().state as string;
  const { setDialog } = useMainContext();
  const { groupRuleService } = useGroupRuleService();
  const { ruleService } = useRuleService();
  const [validated, setValidated] = useState(false);
  const [rules, setRules] = useState<IRule[] | null>(null);
  const [groupRule, setGroupRuleAdd] = useState<IGroupRule>({
    Id: '',
    Description: '',
    AdminRules: [] as IRule[]
  } as IGroupRule);

  const handleRulesAdd = useCallback((id: string) => {
    setGroupRuleAdd(prevState => {
      const { AdminRules } = prevState;

      const adminRuleNotExist = !AdminRules?.some(x => x.Id === id);

      if (adminRuleNotExist) {
        const newRule = { Id: id } as IRule;

        const result = !AdminRules?.length
          ? [newRule]
          : [...(AdminRules as IRule[]), newRule];

        return { ...prevState, AdminRules: result };
      }

      const result = AdminRules?.filter(x => x.Id !== id);
      return { ...prevState, AdminRules: result };
    });
  }, []);

  const handleConfirm = useCallback(async () => {
    try {
      if (groupId) await groupRuleService.update(groupRule);
      else await groupRuleService.add(groupRule);
    } catch (error) {
      console.log(
        `error in AddEditGroupRule - function handleConfirm, error:${error}`
      );
    }
  }, [groupRule, groupId, groupRuleService]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      const form = event.currentTarget;

      event.preventDefault();
      event.stopPropagation();
      setValidated(!form.checkValidity());

      if (form.checkValidity())
        setDialog({ show: true, callBackConfirm: handleConfirm });
    },
    [handleConfirm, setDialog]
  );

  const getData = useCallback(async () => {
    try {
      const rulesResult = await ruleService.getAll();
      setRules(rulesResult);
      if (groupId) {
        const groupRuleResult = await groupRuleService.get(groupId);
        setGroupRuleAdd(groupRuleResult);
      }
    } catch (error) {
      console.log(
        `error in AddEditGroupRule - function getData, error:${error}`
      );
    }
  }, [ruleService, groupId, groupRuleService]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Container>
      <Title backButton> {groupId ? 'Editar' : 'Adicionar'} Grupo </Title>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        onKeyPress={(event: React.KeyboardEvent<HTMLFormElement>) => {
          if (event.key === 'Enter') event.preventDefault();
        }}
      >
        <Row className="mb-3">
          <Form.Group as={Col} sm={12} md={6} controlId="validationName">
            <Form.Label>Descrição</Form.Label>
            <Form.Control
              type="text"
              name="description"
              required
              placeholder="Descrição"
              defaultValue={groupRule.Description}
              onChange={e =>
                setGroupRuleAdd(prevState => ({
                  ...prevState,
                  Description: e.target.value
                }))
              }
            />
            <Form.Control.Feedback type="invalid">
              Campo Obrigatório
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Col sm={12} md={6}>
            <Form.Label>Regras</Form.Label>
            <FilterText
              placeholderInput="Descrição Regra"
              setDataToFilter={setRules}
              dataToFilter={rules}
              nameFieldToFilter="Description"
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} sm={12} md={6} controlId="validationRules">
            <div className="overflow-y">
              {rules &&
                rules.map((rule, index) => (
                  <Form.Switch
                    key={rule.Id}
                    type="switch"
                    label={rule.Description}
                    id={rule.Id}
                    name={rule.Id}
                    value={rule.Id}
                    feedback={
                      !groupRule.AdminRules?.length && index === 0
                        ? 'Campo Obrigatório'
                        : ''
                    }
                    checked={groupRule.AdminRules?.some(r => r.Id === rule.Id)}
                    feedbackType="invalid"
                    onChange={() => handleRulesAdd(rule.Id)}
                    required={!groupRule.AdminRules?.length}
                  />
                ))}
            </div>
          </Form.Group>
        </Row>
        <hr />
        <Button variant="primary" type="submit">
          Salvar
        </Button>
      </Form>
    </Container>
  );
};

export default AddEditGroupRules;
