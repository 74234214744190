import { IOrderHistory } from 'interfaces/IOrder';
import { useCallback, useMemo } from 'react';
import { useFetch } from 'services/useFetch';

interface IResponseOrderHistory {
  ProductContractHistories: IOrderHistory[];
}

export const useOrderHistoryService = () => {
  const { callApi } = useFetch();

  const findByProductContractId = useCallback(
    async (id: number) => {
      const result = await callApi<IResponseOrderHistory>({
        url: `ProductContractHistory/FindOrderByProductContractId/${id}`,
        method: 'get',
        prefixMsgError:
          'error in useOrderHistoryService - findByProductContractId:'
      });
      return result;
    },
    [callApi]
  );

  return useMemo(
    () => ({
      orderHistoryService: {
        findByProductContractId
      }
    }),
    [findByProductContractId]
  );
};
