import Header from 'components/Header';
import MenuApp from 'components/Menu';
import React from 'react';
import { Outlet } from 'react-router-dom';
import * as S from './styles';

const Layout: React.FC = () => (
  <S.Container>
    <Header />
    <MenuApp />
    <S.WrapperContent>
      <S.Content>
        <Outlet />
      </S.Content>
    </S.WrapperContent>
  </S.Container>
);

export default Layout;
