import { IProduct } from 'interfaces/IProduct';
import { useCallback, useMemo } from 'react';
import { useFetch } from './useFetch';

export const useProductService = () => {
  const { callApi } = useFetch();
  interface IProductResponse {
    Products: IProduct[];
  }

  const getAll = useCallback(async () => {
    const result = await callApi<IProductResponse>({
      url: `Product/GetAll`,
      method: 'get',
      prefixMsgError: 'error in ProductService - getAll:'
    });
    return result;
  }, [callApi]);

  const add = useCallback(
    async (product: IProduct) => {
      await callApi({
        url: 'Product/Add',
        method: 'post',
        data: product,
        prefixMsgError: 'error in useProductService - add:'
      });
    },
    [callApi]
  );

  const update = useCallback(
    async (product: IProduct) => {
      await callApi({
        url: `Product/Update/${product.Id}`,
        method: 'put',
        data: product,
        prefixMsgError: 'error in useProductService - update:'
      });
    },
    [callApi]
  );

  const destroy = useCallback(
    async (productId: number) => {
      await callApi({
        url: `Product/Delete/${productId}`,
        method: 'delete',
        prefixMsgError: 'error in useProductService - destroy:'
      });
    },
    [callApi]
  );

  return useMemo(
    () => ({
      productService: {
        getAll,
        add,
        update,
        destroy
      }
    }),
    [getAll, add, update, destroy]
  );
};
