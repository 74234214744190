import { ICompany } from 'interfaces/ICompany';
import { useCallback, useMemo } from 'react';
import { useFetch } from './useFetch';

export interface IResponseCompany {
  Companies: ICompany[];
}
// export interface IResponseCompanyOrganizationTypes {
//   CompanyOrganizationTypes: ICompanyOrganizationTypes[];
// }

// export interface IResponseCompanyState {
//   CompanyStates: ICompanyStates[];
// }

export const useCompanyService = () => {
  const { callApi } = useFetch();

  const getAll = useCallback(async () => {
    const result = await callApi<IResponseCompany>({
      url: 'Company/GetAll',
      method: 'get',
      prefixMsgError: 'error in useCompanyService - getAll:'
    });
    return result;
  }, [callApi]);

  return useMemo(
    () => ({
      companyService: {
        getAll
      }
    }),
    [getAll]
  );
};
