import styled, { css } from 'styled-components';
import { MenuArrowOutline, MenuOutline } from 'styled-icons/evaicons-outline';

export const Container = styled.div`
  position: fixed;
  z-index: 3;
  top: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  background: #2d2d2d;
  .btn-logout {
    width: 100px;
  }
  * {
    box-sizing: border-box;
  }
`;

export const LogoCompany = styled.img`
  padding: 2px 0;
`;

const wrapperICon = css`
  cursor: pointer;
  display: flex;
  padding: 0 20px;
`;
export const WrapperMenuICon = styled.div`
  ${wrapperICon};
  &:hover {
    transform: scale(1.03);
  }
`;
export const WrapperRight = styled.div`
  display: flex;
  opacity: 0;
  width: 100px;
  @media (min-width: 650px) {
    opacity: 1;
    width: auto;
  }
`;

const inconCss = css`
  width: 35px;
  fill: var(--white) !important;
  path {
    color: var(--white) !important;
  }
`;

export const MenuIConClose = styled(MenuArrowOutline)`
  ${inconCss};
`;
export const MenuIConOpen = styled(MenuOutline)`
  ${inconCss};
`;
