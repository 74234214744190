import FilterDate from 'components/FilterDate';
import Title from 'components/Title';
import { IOrderHistory } from 'interfaces/IOrder';
import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import * as S from './style';

const TableOrderHistory: React.FC<{ data: IOrderHistory[] }> = ({ data }) => {
  const [dataFiltered, setDataFiltered] = useState<IOrderHistory[]>(
    [] as IOrderHistory[]
  );

  useEffect(() => {
    const billings: IOrderHistory[] = data?.filter(
      x => x.ProductContractUpperId !== null
    );

    setDataFiltered(billings);
  }, [data]);

  return (
    <S.Container>
      <Title className="border-top h2-title">Histórico de Pedidos</Title>
      <FilterDate
        dataToFilter={dataFiltered}
        nameFieldEndDate="EndDate"
        nameFieldStartDate="StartDate"
        setDataToFilter={setDataFiltered}
      />
      <Row>
        <Col>
          {dataFiltered.length && (
            <div className="table-thead-fixed">
              <Table className="table-striped text-center" responsive>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Nome</th>
                    <th>Data Inicial</th>
                    <th>Data Final</th>
                    <th>Quantidade</th>
                    <th>Preço</th>
                    <th>Responsável</th>
                  </tr>
                </thead>
                <tbody>
                  {dataFiltered.map((order: IOrderHistory) => (
                    <tr key={order.Id}>
                      <td>{order.Id}</td>
                      <td>{order.ProductName}</td>
                      <td>{order.StartDateFmt}</td>
                      <td>{order.EndDateFmt}</td>
                      <td>{order.Quantity}</td>
                      <td>
                        {Number.parseFloat(order.Price).toLocaleString(
                          'pt-BR',
                          {
                            style: 'currency',
                            currency: 'BRL'
                          }
                        )}
                      </td>
                      <td>{order.User}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </Col>
      </Row>
    </S.Container>
  );
};

export default TableOrderHistory;
