import Title from 'components/Title';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ListAdd } from 'styled-icons/zondicons';
import TableRules from './Components/TableRules';
import { Container } from './styles';

const Rules: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Title>Regras</Title>
      <Row>
        <Col xs={12} sm={6}>
          <Button
            variant="outline-primary"
            title="Adicionar Regra"
            onClick={() => navigate('addEditRule')}
          >
            <ListAdd size="20" />
          </Button>
        </Col>
      </Row>
      <br />
      <TableRules />
    </Container>
  );
};

export default Rules;
