import { useMsal } from '@azure/msal-react';
import { useMainContext } from 'context/MainContext';
import { IUserProfile } from 'interfaces/IUserProfile';
import { scopes } from 'microsoft.auth.config';
import React, { useCallback, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { useAuthService } from 'services/authService';
import * as S from './styles';

const Login: React.FC = () => {
  const { profile, setProfile, msalInstance } = useMainContext();
  const [notAuthorized, setNotAuthorized] = useState(false);
  const { authService } = useAuthService();
  const { instance } = useMsal();

  const handleLogin = useCallback(async () => {
    setNotAuthorized(false);

    try {
      const { account } = await instance.loginPopup(scopes);

      if (account) {
        const user: IUserProfile = {
          Email: account.username,
          Name: account.name || '',
          MicrosoftId: account.localAccountId
        };

        const result = (JSON.parse(
          process.env.REACT_APP_MOCHA_LOGIN || ''
        ) as boolean)
          ? await authService.loginMocha(user)
          : await authService.login(user);

        setProfile(result);
      } else {
        setNotAuthorized(true);
      }
    } catch (error) {
      console.log(`error in Loging - function handleLogin, error:${error}`);
    }
  }, [authService, instance, setProfile]);

  if (profile) return <Navigate to="/" replace />;

  return (
    <S.Container>
      <S.WrapperIconBackground>
        <img alt="logo" src="images/img-background-login.svg" />
      </S.WrapperIconBackground>
      <S.WrapperLogin>
        <S.Logo>
          <h1>Admin</h1>
          <img alt="logo" src="images/logo-company-white.svg" />
        </S.Logo>
        <S.WrapperButton>
          <Button onClick={handleLogin}>
            <S.IconMicrosoft />
            <span>Login</span>
            <span className="complement-text"> com Microsoft</span>
          </Button>
        </S.WrapperButton>
        {notAuthorized && <Alert variant="danger">Acesso Negado</Alert>}
      </S.WrapperLogin>
    </S.Container>
  );
};

export default Login;
