import { LabelCustom } from 'components/LabelCustom';
import { useMainContext } from 'context/MainContext';
import {
  IOrderBilling,
  IOrderBillingData,
  IOrderUpdate
} from 'interfaces/IOrder';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useOrderService } from 'services/orderService';
import { masks } from 'utils/masks';
import * as S from './styles';

interface Props {
  data: IOrderBillingData;
  getData: () => Promise<void>;
}

const TableOrderEdit: React.FC<Props> = ({ data, getData }) => {
  const [newOrders, setNewOrders] = useState<IOrderBilling[]>(
    {} as IOrderBilling[]
  );

  const [ordersUpdate, setOrdersUpdate] = useState<IOrderUpdate[]>(
    {} as IOrderUpdate[]
  );

  const [dataFiltered, setDataFiltered] = useState<IOrderBillingData>(
    {} as IOrderBillingData
  );

  const [total, setTotal] = useState(0);

  const { orderService } = useOrderService();

  const { setDialog } = useMainContext();

  const handleInputs = useCallback(
    (idItem: number, priceItem: string) => {
      const price = priceItem ? Number.parseFloat(priceItem) : 0;

      setNewOrders(prevState => {
        if (prevState) {
          const newOrdersData = prevState.map(obj => {
            if (obj.Id === idItem) {
              const newSubTotal =
                obj.SubTotalUntilToday +
                (price / 720) *
                  obj.Quantity *
                  (dataFiltered.TotalHoursRemainingMonth || 0);

              return { ...obj, Price: price, SubTotal: newSubTotal };
            }
            return obj;
          });
          setDataFiltered(prevStateData => ({
            ...prevStateData,
            Billings: newOrdersData
          }));

          setOrdersUpdate((prevStateUpdate: IOrderUpdate[]) =>
            prevStateUpdate.map((x: IOrderUpdate) =>
              x.ProductContractId === idItem ? { ...x, Price: price } : x
            )
          );

          const totalMain = newOrdersData
            .map(obj => obj.SubTotal)
            .reduce((partialSum, value) => partialSum + value, 0);

          setTotal(totalMain);

          return newOrdersData;
        }

        return prevState;
      });
    },
    [dataFiltered.TotalHoursRemainingMonth]
  );

  const buildData = useCallback((orderBillingData: IOrderBillingData) => {
    const billings = orderBillingData.Billings?.filter(
      x => x.ProductContractUpperId !== null
    );
    setNewOrders(billings);
    setOrdersUpdate(
      billings.map(
        x =>
          ({
            ClientId: x.ClientId,
            Price: x.Price,
            ProductContractId: x.Id,
            CreateNewHistoryRegister: true
          } as IOrderUpdate)
      )
    );
    setDataFiltered({
      ...orderBillingData,
      Billings: billings
    });

    setTotal(orderBillingData.Total);
  }, []);

  const handleConfirm = useCallback(async () => {
    try {
      await orderService.updateOrder(ordersUpdate, ordersUpdate[0].ClientId);

      await getData();
    } catch (error) {
      console.log(
        `error in TableOrderEdit - function handleConfirm, error:${error}`
      );
    }
  }, [orderService, ordersUpdate, getData]);

  const handleSave = useCallback(() => {
    setDialog({
      show: true,
      callBackConfirm: handleConfirm
    });
  }, [setDialog, handleConfirm]);

  const handleCheckboxNewRegister = useCallback((checked: boolean) => {
    setOrdersUpdate((prevState: IOrderUpdate[]) =>
      prevState.map((x: IOrderUpdate) => ({ ...x, NewRegister: checked }))
    );
  }, []);

  useEffect(() => {
    buildData(data);
  }, [data, buildData]);

  return (
    <S.Container>
      <Row className="sm-margin-bottom">
        <Col>
          <Form.Check
            type="switch"
            name="checkboxNewRegister"
            id="checkboxNewRegister"
            label="Novo registro?"
            onChange={e => {
              handleCheckboxNewRegister(e.currentTarget.checked);
            }}
            defaultChecked
          />
        </Col>
      </Row>

      {dataFiltered.Billings &&
        dataFiltered.Billings?.map((orderEdit: IOrderBilling) => (
          <Row key={orderEdit.Id} className="md-margin-bottom">
            <Col xs={12} sm={6}>
              <LabelCustom>
                {orderEdit.ProductName}&nbsp;({orderEdit.Quantity})
              </LabelCustom>
              <br />
              <LabelCustom>
                Valor mês: &nbsp;R$
                {orderEdit.SubTotal.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </LabelCustom>
            </Col>
            <Col className="text-center" xs={12} sm={6}>
              <InputGroup>
                <InputGroup.Text id="basic-addon1">R$</InputGroup.Text>
                <S.Field
                  type="number"
                  step=".01"
                  name={orderEdit.ProductName}
                  id={orderEdit.ProductName}
                  onKeyUp={(event: React.FormEvent<HTMLInputElement>) => {
                    masks.currency(event);
                    handleInputs(orderEdit.Id, event.currentTarget.value);
                  }}
                  defaultValue={orderEdit.Price.toFixed(2)}
                />
              </InputGroup>
            </Col>
          </Row>
        ))}
      <Row>
        <Col>
          <div className="float-right">
            <LabelCustom>Total Estimado:&nbsp;</LabelCustom>
            <b>
              R$&nbsp;
              {total.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </b>
          </div>
        </Col>
      </Row>

      {/* <Table className="text-center thead-transparent" borderless>
        <thead>
          <tr>
            <th>Itens</th>
            <th>Qtde</th>
            <th>Valor mês</th>
            <th>Preço</th>
          </tr>
        </thead>
        <tbody>
          {dataFiltered.Billings &&
            dataFiltered.Billings?.map((orderEdit: IOrderBilling) => (
              <tr key={orderEdit.Id}>
                <td>
                  <LabelCustom>{orderEdit.ProductName}</LabelCustom>
                </td>
                <td>
                  <LabelCustom> {orderEdit.Quantity}</LabelCustom>
                </td>
                <td>
                  <LabelCustom>
                    {orderEdit.SubTotal.toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </LabelCustom>
                </td>
                <td>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1">R$</InputGroup.Text>
                    <S.Field
                      type="number"
                      step=".01"
                      name={orderEdit.ProductName}
                      id={orderEdit.ProductName}
                      onKeyUp={(event: React.FormEvent<HTMLInputElement>) => {
                        masks.currency(event);
                        handleInputs(orderEdit.Id, event.currentTarget.value);
                      }}
                      defaultValue={orderEdit.Price.toFixed(2)}
                    />
                  </InputGroup>
                </td>
              </tr>
            ))}
          <tr>
            <td />
            <td />
            <td />
            <td>
              <Form.Check
                type="switch"
                name="checkboxNewRegister"
                id="checkboxNewRegister"
                label="Novo registro?"
                onChange={e => {
                  handleCheckboxNewRegister(e.currentTarget.checked);
                }}
                defaultChecked
              />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <b className="float-right">Total Estimado</b>
            </td>
            <td colSpan={2}>
              <b>
                R${' '}
                {total.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </b>
            </td>
          </tr>
        </tfoot>
      </Table> */}
      <hr />
      <Row>
        <Col xs={12}>
          <Button
            className="float-right"
            variant="primary"
            onClick={handleSave}
          >
            Salvar
          </Button>
        </Col>
      </Row>
    </S.Container>
  );
};

export default TableOrderEdit;
