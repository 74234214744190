import styled, { css } from 'styled-components';

export const WrapperNav = styled.div<{ showMenu: boolean }>`
  z-index: 2;
  height: 100%;
  transition: all 0.3s ease-in-out;
  transform: ${({ showMenu }) =>
    showMenu ? 'translate(0%)' : 'translate(-150%)'};
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  top: 50px;
`;

export const BackGround = styled.div<{ showMenu: boolean }>`
  top: 50px;
  z-index: 1;
  position: fixed;
  z-index: ${({ showMenu }) => (showMenu ? '2' : '-1')};
  height: 100vh;
  width: 100vw;
  background-color: #0000004f;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ showMenu }) => (showMenu ? '1' : '0')};
`;

const borderAfter = css`
  content: '';
  position: absolute;
  width: 2px;
  height: 25px;
  background-color: var(--primary);
`;

export const Nav = styled.nav`
  background: var(--white);

  height: 100%;
  padding-top: 10px;
  box-shadow: 0px 0px 3px var(--color-shadow);
  ul {
    padding: 0;
    list-style-type: none;
    background: var(--white);
    li {
      box-sizing: border-box;
      width: 220px;
      min-width: 100px;
      text-decoration: none;
      border-bottom: 1px solid var(--light-grey3);
      text-transform: capitalize;

      .submenu {
        border-top: 1.5px solid var(--light-grey2);

        a {
          padding-left: 45px;
        }
      }

      a {
        display: table;
        height: 100%;
        width: 100%;
        padding: 10px 0 10px 25px;
        box-sizing: border-box;
        text-decoration: none;
        color: var(--dark-grey);

        ::after {
          ${borderAfter};
          right: 3px;
          transform: scaleY(0);
        }

        :hover {
          transform: scale(1.03);

          ::after {
            transition: all 0.3s ease-in;
            transform: scaleY(1);
          }
        }

        &.active {
          background-color: var(--light-grey4);
          :hover {
            transform: none;
          }
          ::after {
            ${borderAfter};
            right: 0;
            transform: scaleY(1);
          }
        }
      }

      &.logout {
        @media (min-width: 650px) {
          display: none;
        }
        border: none;
        margin-top: 15px;
        min-height: 150px;
        padding: 0 20px;
        .logout-menu {
          margin-top: 10px;
        }
      }
    }
  }
`;
