import styled from 'styled-components';

export const Card = styled.div<{ show?: boolean }>`
  display: ${({ show }) => (show === false ? 'none' : 'block')};
  margin-bottom: 15px;
  padding: 20px;
  height: auto;
  min-width: 250px;
  box-shadow: var(--shadow-standard);
  border-radius: 10px;
`;
