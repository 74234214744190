import styled from 'styled-components';

export const TextCustom = styled.span<{
  colorFont?: string;
  textTransform?: string;
}>`
  text-transform: ${({ textTransform }) => textTransform || 'capitalize'};

  display: inline-block;

  color: ${({ colorFont }) =>
    colorFont ? `var(--${colorFont})` : 'var(--color-text)'};
  width: 100%;
  max-width: 100%;
  word-wrap: break-word;

  small {
    color: var(--color-small);
  }
`;
