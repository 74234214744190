import React from 'react';

export const masks = {
  currency: (e: React.FormEvent<HTMLInputElement>) => {
    let { value } = e.currentTarget;
    value = value.replace(/\D/g, '');
    if (!value.length) {
      e.currentTarget.value = value;
      return e;
    }
    value = `${(Number.parseFloat(value) / 100).toFixed(2)}`;
    value = value.replace(/(\d)(\d{2})$/, '$1.$2');
    e.currentTarget.value = value;
    return e;
  }
};
