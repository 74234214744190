import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { FilterAlt } from 'styled-icons/material';
import { Container } from './styles';

interface Props {
  setDataToFilter: React.Dispatch<React.SetStateAction<any[] | null>>;
  placeholderInput: string;
  nameFieldToFilter?: string;
  dataToFilter?: any[] | null;
}

const FilterTextPriceList: React.FC<Props> = ({
  nameFieldToFilter,
  setDataToFilter,
  dataToFilter,
  placeholderInput
}) => {
  const [inputSearch, setInputSearch] = useState<string>('');
  const [filtered, setFiltered] = useState<boolean>(false);

  // estas duas funcoes zeram o campo de pesqeuisa, caso alterem os combos de client ou de company
  document.getElementById('select-company')?.addEventListener('change', () => {
    setInputSearch('');
    setFiltered(false);
  });
  document.getElementById('select-client')?.addEventListener('change', () => {
    setInputSearch('');
    setFiltered(false);
  });

  const memoData = useMemo<any[] | null>((): any[] | null => {
    if (dataToFilter) {
      return dataToFilter;
    }
    return null;
  }, [dataToFilter]);

  const [dataOriginal, setDataOriginal] = useState<any[] | null>(null);

  const searchHandle = async (textSearch: string) => {
    if (dataOriginal && nameFieldToFilter) {
      if (!textSearch.trim()) {
        setDataToFilter(dataOriginal);
        setFiltered(false);
      } else if (nameFieldToFilter.includes(',')) {
        const bigArray: any[] = [];
        nameFieldToFilter
          .split(',')
          .forEach(a =>
            dataOriginal
              .filter(x =>
                `${x[a]}`
                  .toLowerCase()
                  .trim()
                  .includes(textSearch.trim().toLowerCase())
              )
              .map(e => bigArray.push(e))
          );
        const arrayResult = bigArray.filter((a, b, c) => c.indexOf(a) === b);
        arrayResult.sort((a, b) => a.Id - b.Id);
        setDataToFilter(arrayResult);
        setFiltered(!arrayResult?.length);
      } else {
        const resultData = dataOriginal.filter(x =>
          `${x[nameFieldToFilter]}`
            .toLowerCase()
            .trim()
            .includes(textSearch.trim().toLowerCase())
        );
        setDataToFilter(resultData);
        setFiltered(!resultData?.length);
      }
    }
  };

  useEffect(() => {
    if (!dataOriginal && dataToFilter) {
      const newData = dataToFilter.filter(x => true);
      setDataOriginal(newData);
    }
    if (dataOriginal && dataToFilter && inputSearch.length < 1) {
      setDataOriginal(memoData);
    }
  }, [dataOriginal, dataToFilter, memoData, inputSearch]);

  return (
    <Container>
      <Row className="sm-margin-bottom">
        <Col xs={12}>
          <InputGroup>
            <Form.Control
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setInputSearch(event.target.value);
                searchHandle(event.target.value);
              }}
              value={inputSearch}
              placeholder={placeholderInput}
            />
            <Button
              variant="outline-primary"
              onClick={() => {
                searchHandle(inputSearch);
              }}
            >
              <FilterAlt width="24px" />
            </Button>
          </InputGroup>
        </Col>
      </Row>
      {filtered && (
        <Row>
          <Col xs={12}>
            <br />
            <h3>Nenhum registro retornado</h3>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default FilterTextPriceList;
