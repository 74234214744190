import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_CLIENTID as string,
    authority: process.env.REACT_APP_MICROSOFT_AUTHORITY as string,
    redirectUri: process.env.REACT_APP_MICROSOFT_REDIRECT_URL as string
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  }
};

export const scopes = {
  scopes: [process.env.REACT_APP_MICROSOFT_SCOPES as string]
};
