/* eslint-disable no-param-reassign */
import Allow from 'components/Allow';
import { useMainContext } from 'context/MainContext';
import { enumRules } from 'enum/enumRules';
import { IProductPrice } from 'interfaces/IProduct';
import React, { useCallback } from 'react';
import {
  Button,
  Col,
  FormControl,
  InputGroup,
  Row,
  Table
} from 'react-bootstrap';

import { Block, Check } from 'styled-icons/boxicons-regular';
import { masks } from 'utils/masks';
import TableTooltips from '../TableTooltips';

type Props = {
  company: number;
  list: IProductPrice[] | null;
  setProductPriceList: React.Dispatch<
    React.SetStateAction<IProductPrice[] | null>
  >;
};

const TablePriceListCompany: React.FC<Props> = ({
  list,
  setProductPriceList,
  company
}) => {
  const { setDialog, profile } = useMainContext();
  const productPriceList = list as IProductPrice[];

  // zera Cost e PriceCompany, isto afetara o style de ativo/inativo
  const nullPriceCost = useCallback(
    (item: IProductPrice, disable: boolean) => {
      if (item.CompanyId === 1) {
        setProductPriceList(prevState => {
          const prev = prevState as IProductPrice[];
          prev.forEach(x => {
            if (x.ProductId === item.ProductId) {
              x.CostCompany = disable ? null : x.CostCompany || 0;
              x.PriceCompany = disable ? null : x.PriceCompany || 0;
            }
          });
          return [...prev];
        });
      } else {
        setProductPriceList(prevState => {
          const prev = prevState as IProductPrice[];
          prev.forEach(x => {
            if (x.ProductId === item.ProductId) {
              x.CostCompany = disable
                ? null
                : item.CostCompany || item.CostCompanyDefault;
              x.PriceCompany = disable
                ? null
                : item.PriceCompany || item.PriceCompanyDefault;
            }
          });
          return [...prev];
        });
      }
    },
    [setProductPriceList]
  );

  const handleActive = useCallback(
    (item: IProductPrice, active: boolean) => {
      if (active) {
        setDialog({
          show: true,
          textBody:
            'Ao ativar este produto você poderá definir um custo e preço específico deste para esta companhia. Você poderá, também, desativá-lo posteriormente caso seja necessário.',
          callBackConfirm: () => nullPriceCost(item, false)
        });
      } else {
        setDialog({
          show: true,
          textBody:
            'Ao desativar, o produto retornará os valores de preço e custo padrões da companhia de origem. Você poderá, também, ativá-lo posteriormente caso seja necessário.',
          callBackConfirm: () => nullPriceCost(item, true),
          callBackCancel: () => nullPriceCost(item, false)
        });
      }
    },
    [setDialog, nullPriceCost]
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: IProductPrice,
    field: 'cost' | 'price'
  ) => {
    let value: number | null;
    if (e.target.value.length === 0) {
      handleActive(item, false);
    } else value = Number(e.target.value);

    setProductPriceList(prevState => {
      const prev = prevState as IProductPrice[];
      prev.forEach(x => {
        if (x.ProductId === item.ProductId) {
          if (field === 'cost') x.CostCompany = value;
          else x.PriceCompany = value;
        }
      });
      return [...prev];
    });
  };

  const brl = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });

  return (
    <div>
      {productPriceList && productPriceList.length > 0 && (
        <Row>
          <Col>
            <div className="table-thead-fixed">
              <Table className="table text-center" responsive>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Nome</th>
                    <th>Nome Interno</th>
                    <th>Tipo</th>
                    <th>
                      Custo Base{' '}
                      <TableTooltips description="Valor faturado real para a Golden." />
                    </th>
                    <th>
                      Preço Base{' '}
                      <TableTooltips description="Preço de venda desta companhia para seus clientes." />
                    </th>
                    <th>
                      <Allow
                        ruleId={enumRules.ProductPrice.CanWriteProductPrice}
                      >
                        <TableTooltips description="Disponibilizar produto para seus clientes. Caso desative esta opção, o produto ficará indisponível apenas para aqueles clientes que não possuem um preço personalizado." />
                      </Allow>
                    </th>
                  </tr>
                </thead>
                <tbody id="tbody-company">
                  {productPriceList.map((productPrice: IProductPrice) => (
                    <tr
                      style={{
                        color: productPrice.CostCompany === null ? 'red' : ''
                      }}
                      key={`${productPrice.ProductId}${productPrice.CompanyId}`}
                    >
                      <td>{productPrice.ProductId}</td>
                      <td>{productPrice.ProductName}</td>
                      <td>{productPrice.ProductInternalName}</td>
                      <td>{productPrice.ProductType}</td>
                      <td>
                        {profile?.Rules?.includes(
                          enumRules.ProductPrice.CanWriteProductPrice
                        ) || profile?.IsAdm ? (
                          <InputGroup>
                            <InputGroup.Text>R$</InputGroup.Text>
                            <FormControl
                              key={`cost${productPrice.ProductId}${productPrice.CompanyId}`}
                              type="number"
                              inputMode="numeric"
                              onBlur={e => {
                                if (e.target.value.length === 0)
                                  handleActive(productPrice, false);
                              }}
                              min="0"
                              step=".01"
                              disabled={productPrice.CostCompany === null}
                              defaultValue={
                                productPrice.CostCompany != null
                                  ? productPrice.CostCompany.toFixed(2)
                                  : productPrice.CostCompanyDefault?.toFixed(2)
                              }
                              value={
                                productPrice.CostCompany?.toFixed(2) as string
                              }
                              onKeyDown={e => {
                                if (
                                  e.key === 'ArrowUp' ||
                                  e.key === 'ArrowDown'
                                )
                                  e.preventDefault();
                              }}
                              onChange={e => {
                                masks.currency(
                                  e as React.FormEvent<HTMLInputElement>
                                );
                                handleChange(
                                  e as React.ChangeEvent<HTMLInputElement>,
                                  productPrice,
                                  'cost'
                                );
                              }}
                            />
                          </InputGroup>
                        ) : (
                          <>
                            {brl.format(
                              (productPrice.CostCompany ||
                                productPrice.CostCompanyDefault) as number
                            )}
                          </>
                        )}
                      </td>
                      <td>
                        {profile?.Rules?.includes(
                          enumRules.ProductPrice.CanWriteProductPrice
                        ) || profile?.IsAdm ? (
                          <InputGroup>
                            <InputGroup.Text id="basic-addon1">
                              R$
                            </InputGroup.Text>
                            <FormControl
                              key={`price${productPrice.ProductId}${productPrice.CompanyId}`}
                              type="number"
                              onBlur={e => {
                                if (e.target.value.length === 0)
                                  handleActive(productPrice, false);
                              }}
                              min="0"
                              step=".01"
                              value={
                                productPrice.PriceCompany?.toFixed(2) as string
                              }
                              disabled={productPrice.PriceCompany === null}
                              defaultValue={
                                productPrice.PriceCompany != null
                                  ? productPrice.PriceCompany.toFixed(2)
                                  : productPrice.PriceCompanyDefault?.toFixed(2)
                              }
                              onKeyDown={e => {
                                if (
                                  e.key === 'ArrowUp' ||
                                  e.key === 'ArrowDown'
                                )
                                  e.preventDefault();
                              }}
                              onChange={e => {
                                masks.currency(
                                  e as React.FormEvent<HTMLInputElement>
                                );
                                handleChange(
                                  e as React.ChangeEvent<HTMLInputElement>,
                                  productPrice,
                                  'price'
                                );
                              }}
                            />
                          </InputGroup>
                        ) : (
                          <>
                            {brl.format(
                              (productPrice.PriceCompany ||
                                productPrice.PriceCompanyDefault) as number
                            )}
                          </>
                        )}
                      </td>
                      <td>
                        <Allow
                          ruleId={enumRules.ProductPrice.CanWriteProductPrice}
                        >
                          <Button
                            variant="outline-primary"
                            onClick={() =>
                              handleActive(
                                productPrice,
                                productPrice.CostCompany === null
                              )
                            }
                          >
                            {productPrice.CostCompany === null ? (
                              <Block title="Desativado" size={25} />
                            ) : (
                              <Check title="Ativado" size={25} />
                            )}
                          </Button>
                        </Allow>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default TablePriceListCompany;
