import { IProductType } from 'interfaces/IProduct';
import { useCallback, useMemo } from 'react';
import { useFetch } from './useFetch';

export const useProductTypeService = () => {
  const { callApi } = useFetch();

  interface IProductTypeResponse {
    ProductTypes: IProductType[];
  }

  const getAll = useCallback(async () => {
    const result = await callApi<IProductTypeResponse>({
      url: `ProductType/GetAll`,
      method: 'get',
      prefixMsgError: 'error in ProductTypeService - getAll:'
    });
    return result;
  }, [callApi]);

  const findOne = useCallback(
    async (productTypeId: number) => {
      const result = await callApi<IProductType>({
        url: `ProductType/FindProductTypeByProductTypeId/${productTypeId}`,
        method: 'get',
        prefixMsgError: 'error in ProductTypeService - findOne:'
      });
      return result;
    },
    [callApi]
  );

  const add = useCallback(
    async (product: IProductType) => {
      await callApi({
        url: 'ProductType/Add',
        method: 'post',
        data: product,
        prefixMsgError: 'error in ProductTypeService - add:'
      });
    },
    [callApi]
  );

  const update = useCallback(
    async (productType: IProductType) => {
      await callApi({
        url: `ProductType/Update/${productType.Id}`,
        method: 'put',
        data: productType,
        prefixMsgError: 'error in ProductTypeService - update:'
      });
    },
    [callApi]
  );

  const destroy = useCallback(
    async (productTypeId: number) => {
      await callApi({
        url: `ProductType/Delete/${productTypeId}`,
        method: 'delete',
        prefixMsgError: 'error in ProductTypeService - destroy:'
      });
    },
    [callApi]
  );

  return useMemo(
    () => ({
      productTypeService: {
        getAll,
        findOne,
        add,
        update,
        destroy
      }
    }),
    [getAll, add, findOne, update, destroy]
  );
};
