import FilterText from 'components/FilterText';
import { IGroupRule } from 'interfaces/IGroupRule';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Col,
  OverlayTrigger,
  Popover,
  Row,
  Table
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useGroupRuleService } from 'services/groupRuleService';
import { Users } from 'styled-icons/fa-solid';
import { Edit } from 'styled-icons/material';
import PopoverBodyRules from '../PopoverBodyRules';
import * as S from './style';

const TableGroupRules: React.FC = () => {
  const [groupRules, setGroupRules] = useState<IGroupRule[] | null>(null);
  const { groupRuleService } = useGroupRuleService();
  const navigate = useNavigate();

  const getData = useCallback(async () => {
    try {
      const result = await groupRuleService.getAll();
      setGroupRules(result);
    } catch (error) {
      console.log(
        `error in TableGroupRules - function getData, error:${error}`
      );
    }
  }, [groupRuleService]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <S.Container>
      <FilterText
        placeholderInput="Nome Grupo"
        nameFieldToFilter="Name"
        dataToFilter={groupRules}
        setDataToFilter={setGroupRules}
      />
      {groupRules && groupRules.length > 0 && (
        <Row>
          <Col>
            <div className="table-thead-fixed">
              <Table className="table-striped text-center" responsive>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Descrição</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {groupRules.map((groupRule: IGroupRule) => (
                    <tr key={groupRule.Id}>
                      <td>
                        <span title={groupRule.Id} className="span-ellipsis ">
                          {groupRule.Id}
                        </span>
                      </td>
                      <td>{groupRule.Description}</td>
                      {groupRule.AdminRules && (
                        <td>
                          <OverlayTrigger
                            key={groupRule.Id}
                            placement="top"
                            trigger={['click', 'hover']}
                            overlay={
                              <Popover>
                                <Popover.Header>
                                  Grupos Associado
                                </Popover.Header>
                                <Popover.Body>
                                  <PopoverBodyRules
                                    rules={groupRule.AdminRules}
                                  />
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <Users size="24" color="var(--primary)" />
                          </OverlayTrigger>
                        </td>
                      )}
                      <td>
                        <Button
                          type="button"
                          variant="outline-primary"
                          onClick={() =>
                            navigate('addEditGroupRules', {
                              state: groupRule.Id
                            })
                          }
                          title="Editar"
                        >
                          <Edit size={20} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      )}
    </S.Container>
  );
};

export default TableGroupRules;
