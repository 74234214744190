import { IProductPrice, IProductPriceUpdate } from 'interfaces/IProduct';
import { useCallback, useMemo } from 'react';
import { useFetch } from './useFetch';

export interface IResponseProductPrice {
  ProductPrices: IProductPrice[];
}

export const useProductPriceService = () => {
  const { callApi } = useFetch();

  const findProductPriceByCompanyId = useCallback(
    async (id: number) => {
      const result = await callApi<IResponseProductPrice>({
        url: `ProductPrice/FindProductPriceByCompanyId/${id}`,
        method: 'get',
        prefixMsgError:
          'error in useProductPriceService - findProductPriceByCompanyId:'
      });
      return result;
    },
    [callApi]
  );

  const findProductPriceByClientId = useCallback(
    async (id: number) => {
      const result = await callApi<IResponseProductPrice>({
        url: `ProductPrice/FindProductPriceByClientId/${id}`,
        method: 'get',
        prefixMsgError:
          'error in useProductPriceService - findProductPriceByClientId:'
      });
      return result;
    },
    [callApi]
  );

  const updateProductPrice = useCallback(
    async (priceList: IProductPriceUpdate[]) => {
      const result = await callApi({
        url: `ProductPrice/UpdateProductPrice`,
        method: 'put',
        data: priceList,
        prefixMsgError: 'error in useProductPriceService - updateProductPrice:'
      });
      return result;
    },
    [callApi]
  );

  return useMemo(
    () => ({
      productPriceService: {
        findProductPriceByCompanyId,
        updateProductPrice,
        findProductPriceByClientId
      }
    }),
    [
      findProductPriceByCompanyId,
      updateProductPrice,
      findProductPriceByClientId
    ]
  );
};
