export const enumRules = {
  Client: {
    CanReadClient: '04c7d577-6c32-4996-a122-17186ecda0f6',
    CanWriteClient: '43547a80-4023-4675-ae62-58ff26b54cbd'
  },
  Order: {
    CanReadOrder: '5ee2d5e9-141e-4639-b0d3-dc5ae358b98d',
    CanWriteOrder: '7eeb0e61-cbfb-4f9c-b966-544aaa969f47'
  },
  Report: {
    CanReadReportBilling: '964cf67b-dc78-43e5-9f64-0a8b37f7cd7e'
  },
  Product: {
    CanReadProduct: '750f30dd-0bf0-45a4-828f-1f4f95231589',
    CanWriteProduct: '43fdaa61-83fd-419d-8a61-69524940a40b'
  },
  ProductType: {
    CanReadProductType: '08fed1b6-7ab3-43f1-8e1a-d26572d953e6',
    CanWriteProductType: 'f12155f7-47fe-4cc9-a82d-86c974f0951c'
  },
  ProductPrice: {
    CanReadProductPrice: '8d53ea5c-c6ce-49ba-aba4-eb96f3a3d802',
    CanWriteProductPrice: '258714e9-9f38-4567-8ebc-c21316b738d7'
  },
  Admin: '71ea0d31-78bd-4e4d-ada5-44a69db4ffe2'
};
