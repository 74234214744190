import Layout from 'components/Layout';
import { useMainContext } from 'context/MainContext';
import { enumRules } from 'enum/enumRules';
import ClientDetails from 'pages/ClientDetails';
import Clients from 'pages/Clients';
import GroupRules from 'pages/GroupRules';
import AddEditGroupRules from 'pages/GroupRules/components/AddEditGroupRules';
import Login from 'pages/Login';
import OrderDatail from 'pages/OrderDetail';
import Orders from 'pages/Orders';
import { ProductPricelist } from 'pages/ProductPricelist';
import { Products } from 'pages/Products';
import AddEditProduct from 'pages/Products/Components/AddEditProduct';
import { ProductType } from 'pages/ProductType';
import AddEditProductType from 'pages/ProductType/Components/AddEditProductType';
import ReportBilling from 'pages/ReportBilling';
import Rules from 'pages/Rules';
import AddEditRule from 'pages/Rules/Components/AddEditRule';
import Users from 'pages/Users';
import AddEditUser from 'pages/Users/components/AddEditUser';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

interface Props {
  Component: React.FunctionComponent;
  ruleId?: string;
}

const PrivateRoute: React.FC<Props> = ({ Component, ruleId }) => {
  const { profile, isAuthenticated } = useMainContext();

  if (isAuthenticated()) {
    if (ruleId) {
      if (profile?.Rules?.includes(ruleId) || profile?.IsAdm)
        return <Component />;
      return <Navigate to="/unauthorized" replace />;
    }
    return <Component />;
  }

  return <Navigate to="/login" replace />;
};

const MainRoutes: React.FC = () => {
  const route = <Route path="client" element={<Clients />} />;
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Layout />}>
        <Route index element={<Navigate to="/client" replace />} />
        <Route
          path="client"
          element={
            <PrivateRoute
              Component={Clients}
              ruleId={enumRules.Client.CanReadClient}
            />
          }
        />
        <Route
          path="clientDetails/:id"
          element={
            <PrivateRoute
              Component={ClientDetails}
              ruleId={enumRules.Client.CanReadClient}
            />
          }
        />
        <Route
          path="clientDetails/:id/orders/"
          element={
            <PrivateRoute
              Component={Orders}
              ruleId={enumRules.Order.CanReadOrder}
            />
          }
        />
        <Route
          path="orderDetail/:id"
          element={
            <PrivateRoute
              Component={OrderDatail}
              ruleId={enumRules.Order.CanReadOrder}
            />
          }
        />
        <Route
          path="reportBilling"
          element={
            <PrivateRoute
              Component={ReportBilling}
              ruleId={enumRules.Report.CanReadReportBilling}
            />
          }
        />
        <Route
          path="users"
          element={<PrivateRoute Component={Users} ruleId={enumRules.Admin} />}
        />
        <Route
          path="users/addEditUser"
          element={
            <PrivateRoute Component={AddEditUser} ruleId={enumRules.Admin} />
          }
        />
        <Route
          path="rules"
          element={<PrivateRoute Component={Rules} ruleId={enumRules.Admin} />}
        />
        <Route
          path="rules/addEditRule"
          element={
            <PrivateRoute Component={AddEditRule} ruleId={enumRules.Admin} />
          }
        />
        <Route
          path="groupRules"
          element={
            <PrivateRoute Component={GroupRules} ruleId={enumRules.Admin} />
          }
        />
        <Route
          path="groupRules/addEditGroupRules"
          element={
            <PrivateRoute
              Component={AddEditGroupRules}
              ruleId={enumRules.Admin}
            />
          }
        />
        <Route
          path="product/catalog"
          element={
            <PrivateRoute
              Component={Products}
              ruleId={enumRules.Product.CanReadProduct}
            />
          }
        />
        <Route
          path="product/catalog/addEditProduct"
          element={
            <PrivateRoute
              Component={AddEditProduct}
              ruleId={enumRules.Product.CanReadProduct}
            />
          }
        />
        <Route
          path="product/type"
          element={
            <PrivateRoute
              Component={ProductType}
              ruleId={enumRules.ProductType.CanReadProductType}
            />
          }
        />
        <Route
          path="product/type/addEditProductType"
          element={
            <PrivateRoute
              Component={AddEditProductType}
              ruleId={enumRules.ProductType.CanReadProductType}
            />
          }
        />
        <Route
          path="product/price"
          element={
            <PrivateRoute
              Component={ProductPricelist}
              ruleId={enumRules.ProductPrice.CanReadProductPrice}
            />
          }
        />
        <Route
          path="product/price/addEditProductPrice"
          element={
            <PrivateRoute
              Component={AddEditProduct}
              ruleId={enumRules.ProductPrice.CanReadProductPrice}
            />
          }
        />
      </Route>
      <Route path="*" element={<h1>Página não encontrada</h1>} />
      <Route path="unauthorized" element={<h1>Acesso não autorizado</h1>} />
    </Routes>
  );
};

export const AppRoutes = React.memo(MainRoutes);
