import styled from 'styled-components';
import { Microsoft } from 'styled-icons/boxicons-logos';

export const Container = styled.div`
  background-color: var(--primary);
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;

  @media (min-width: 650px) {
    justify-content: flex-end;
  }
`;

export const WrapperIconBackground = styled.div`
  img {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
  }
`;
export const Logo = styled.div`
  text-align: left;
  img {
    width: 240px;
  }
  h1 {
    width: fit-content;
    color: black;
  }
`;

export const WrapperLogin = styled.div`
  display: flex;
  border-radius: 40px;
  background-color: white;
  flex-direction: column;
  align-items: center;
  height: 85vh;
  width: 90vw;
  position: absolute;
  padding: 56px;
  box-shadow: -10px 0px 18px #03312929;
  @media (min-width: 650px) {
    border-radius: 40px 0px 0px 40px;
    height: 100vh;
    width: 56vw;
  }
`;

export const WrapperButton = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    padding: 10px 25px;
    span {
      &.complement-text {
        display: none;
      }
      &:first-child {
        margin-left: 10px;
      }
    }

    @media (min-width: 427px) {
      padding: 10px 5px;
      span.complement-text {
        display: initial;
      }
    }

    @media (min-width: 500px) {
      padding: 10px 15px;
    }
  }
`;

export const IconMicrosoft = styled(Microsoft)`
  width: 35px;
  color: var(--white);
`;
