import {
  IClient,
  IClientOrganizationTypes,
  IClientStates,
  IClientUpdate
} from 'interfaces/IClient';
import { useCallback, useMemo } from 'react';
import { useFetch } from './useFetch';

export interface IResponseClient {
  Clients: IClient[];
}
export interface IResponseClientOrganizationTypes {
  ClientOrganizationTypes: IClientOrganizationTypes[];
}

export interface IResponseClientState {
  ClientStates: IClientStates[];
}

export const useClientService = () => {
  const { callApi } = useFetch();

  const findClientByCnpj = useCallback(
    async (cnpj: string) => {
      const { Clients } = await callApi<IResponseClient>({
        url: `Client/FindClientByCnpj/${cnpj}`,
        method: 'get',
        prefixMsgError: 'error in ClientService - findClientByCnpj:'
      });
      return Clients;
    },
    [callApi]
  );

  const findClientByName = useCallback(
    async (name: string) => {
      const { Clients } = await callApi<IResponseClient>({
        url: `Client/FindClientByName/${name}`,
        method: 'get',
        prefixMsgError: 'error in ClientService - findClientByName:'
      });
      return Clients;
    },
    [callApi]
  );

  const findClientByCnpjOrName = useCallback(
    async (textSearch: string): Promise<IClient[]> => {
      let result: IClient[];
      const numberPattern = /^[0-9]*$/;
      const textNumber = textSearch.replace(/\D/g, '');
      if (textNumber && numberPattern.test(textNumber)) {
        result = await findClientByCnpj(textNumber);
      } else {
        result = await findClientByName(textSearch);
      }
      return result;
    },
    [findClientByCnpj, findClientByName]
  );

  const findClientByCompanyId = useCallback(
    async (id: number) => {
      const result = await callApi<IResponseClient>({
        url: `Client/FindClientByCompanyId/${id}`,
        method: 'get',
        prefixMsgError: 'error in ClientService - findClientByCompanyId:'
      });
      return result;
    },
    [callApi]
  );

  const getAllOrganizationType = useCallback(async () => {
    const result = await callApi<IResponseClientOrganizationTypes>({
      url: `ClientOrganizationType/GetAll`,
      method: 'get',
      prefixMsgError: 'error in ClientService - getAllOrganizationType:'
    });
    return result;
  }, [callApi]);

  const getAllClientStates = useCallback(async () => {
    const result = await callApi<IResponseClientState>({
      url: `ClientState/GetAll`,
      method: 'get',
      prefixMsgError: 'error in ClientService - getAllClientState:'
    });
    return result;
  }, [callApi]);

  const updateClient = useCallback(
    async (clientUpdateData: IClientUpdate[], clientId: number) => {
      const result = await callApi({
        url: `Client/UpdateClient/${clientId}`,
        method: 'put',
        data: clientUpdateData,
        prefixMsgError: 'error in ClientService - updateClient'
      });
    },
    [callApi]
  );

  return useMemo(
    () => ({
      clientService: {
        findClientByCnpjOrName,
        findClientByCompanyId,
        getAllOrganizationType,
        getAllClientStates,
        updateClient
      }
    }),
    [
      findClientByCnpjOrName,
      findClientByCompanyId,
      updateClient,
      getAllOrganizationType,
      getAllClientStates
    ]
  );
};
