import styled from 'styled-components';
import { ArrowLeftSquareFill } from 'styled-icons/bootstrap';

export const Container = styled.label`
  color: var(--primary);
  &:hover {
    opacity: 0.7;
  }
  cursor: pointer;
`;
export const IconBack = styled(ArrowLeftSquareFill)`
  width: 25px;
`;
