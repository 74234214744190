import React, { useEffect } from 'react';
import { Container } from './styles';

const Loading: React.FC<{ show?: boolean }> = ({ show }) => {
  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', show);
  }, [show]);
  return (
    <Container show={show}>
      <div className="spinner spinner-border text-light" role="status" />
      <span className="sr-only text-light">Processando...</span>
    </Container>
  );
};

export default Loading;
