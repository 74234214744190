import FilterText from 'components/FilterText';
import Title from 'components/Title';
import { useMainContext } from 'context/MainContext';
import { IClient } from 'interfaces/IClient';
import React, { useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useClientService } from 'services/clientService';
import { RightArrow } from 'styled-icons/boxicons-solid';
import * as S from './styles';

const Client: React.FC = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState<IClient[] | null>(null);
  const { clientService } = useClientService();
  const { setDialog } = useMainContext();

  return (
    <S.Container>
      <Title>Clientes</Title>
      <FilterText
        callBackApi={clientService.findClientByCnpjOrName}
        setDataToFilter={setClients}
        placeholderInput="Razão Social, Nome Fantasia ou CNPJ"
      />
      {clients && clients?.length > 0 && (
        <Row>
          <Col>
            <div className="table-thead-fixed">
              <Table className="table-striped text-center" responsive>
                <thead>
                  <tr>
                    <th> </th>
                    <th>Companhia</th>
                    <th>Razão Social</th>
                    <th>Nome Fantasia</th>
                    <th>CNPJ</th>
                  </tr>
                </thead>
                <tbody>
                  {clients.map((client: IClient, index: number) => (
                    <tr
                      className="pointer"
                      key={client.Id.toString()}
                      onClick={() =>
                        navigate(`/clientDetails/${client.Id}`, {
                          state: client
                        })
                      }
                    >
                      <td>
                        <RightArrow
                          className="right-arrow"
                          size="20"
                          color="var(--medium-grey)"
                        />
                      </td>
                      <td>{client.CompanyName}</td>
                      <td>{client.Name}</td>
                      <td>{client.FantasyName}</td>
                      <td>{client.Cnpj}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      )}
    </S.Container>
  );
};

export default Client;
