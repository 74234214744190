import { AxiosError, AxiosRequestConfig } from 'axios';
import { useMainContext } from 'context/MainContext';
import { IResponse } from 'interfaces/IResponse';
import { useCallback } from 'react';
import api from './api';

export const useFetch = () => {
  const { setLoading, setDialog } = useMainContext();

  interface IConfig extends AxiosRequestConfig {
    prefixMsgError?: string;
    hideDialogSucess?: boolean;
  }

  async function handleCallApi<TResponse = unknown>(
    config: IConfig
  ): Promise<TResponse> {
    interface IRensponseFetch extends IResponse {
      result: TResponse;
    }

    setLoading(true);

    try {
      const {
        data: { errors, result, success }
      } = await api(config);

      if (!success) throw errors;

      if (
        !config.hideDialogSucess &&
        config.method &&
        !config.method.toLowerCase().includes('get')
      ) {
        setDialog({
          show: true,
          textTitle: 'Operação realizada com sucesso',
          isAlert: true
        });
      }

      return result;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.status === 400) {
        setDialog({
          show: true,
          isAlert: true,
          isError: true,
          textTitle: 'Atenção',
          textError:
            error.response.data.errors.ValidationResult ||
            error.response.data.errors
        });
      } else {
        const textError = Array.isArray(error)
          ? error
          : [JSON.stringify(error)];

        setDialog({
          show: true,
          isAlert: true,
          isError: true,
          textTitle: 'Aconteu um erro inesperado',
          textError
        });
      }
      throw error;
    } finally {
      setLoading(false);
    }
  }

  const callApi = useCallback(handleCallApi, [setDialog, setLoading]);
  return { callApi };
};
