import { Card, Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const Title = styled.h3``;

export const Container = styled(Modal)`
  .modal-body-max-height {
    max-height: 300px;
    overflow-y: auto;
  }
  .alert {
    margin-bottom: 0 !important;
  }
`;

type StyledCardProps = {
  selected: boolean;
};

export const StyledCard = styled(Card)<StyledCardProps>`
  height: 100%;
  padding: 7px;
  cursor: pointer;
  box-shadow: ${a =>
    a.selected
      ? '0 1px 1px #ff4f28, 0 2px 2px #ff4f28, 0 4px 4px #ff4f28, 0 8px 8px #ff4f28'
      : 'none'};

  transition: 0.5s;
`;
