/* eslint-disable dot-notation */
import Title from 'components/Title';
import { useMainContext } from 'context/MainContext';
import { enumStatus } from 'enum/enumStatus';
import { IGroupRule } from 'interfaces/IGroupRule';
import { IUser } from 'interfaces/IUser';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGroupRuleService } from 'services/groupRuleService';
import { useUserService } from 'services/userService';
import { Container } from './styles';

const AddEditUser: React.FC = () => {
  const user = useLocation().state as IUser;
  const navigate = useNavigate();
  const { setDialog } = useMainContext();
  const { groupRuleService } = useGroupRuleService();
  const { userService } = useUserService();
  const [validated, setValidated] = useState(false);
  const [groupRules, setGroupRules] = useState<IGroupRule[] | null>(null);
  const [userAddEdit, setUserAddEdit] = useState<IUser>({
    Name: '',
    AdminGroupRules: [] as IGroupRule[],
    Email: '',
    IsAdm: false,
    Status: enumStatus.Inactive
  } as IUser);

  const handleGroupsAdd = useCallback((id: string) => {
    setUserAddEdit(prevState => {
      const { AdminGroupRules } = prevState;

      if (!AdminGroupRules?.some(x => x.Id === id)) {
        const newGroupRule = { Id: id } as IGroupRule;

        const result = !AdminGroupRules?.length
          ? [newGroupRule]
          : [...AdminGroupRules, newGroupRule];

        return { ...prevState, AdminGroupRules: result };
      }

      const result = AdminGroupRules?.filter(x => x.Id !== id);
      return { ...prevState, AdminGroupRules: result };
    });
  }, []);

  const handleConfirm = useCallback(async () => {
    try {
      if (user?.Id) await userService.update(userAddEdit);
      else await userService.add(userAddEdit);
      navigate(-1);
    } catch (error) {
      console.log(
        `error in AddEditUser - function handleConfirm, error:${error}`
      );
    }
  }, [navigate, user?.Id, userAddEdit, userService]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      const form = event.currentTarget;

      event.preventDefault();
      event.stopPropagation();
      setValidated(!form.checkValidity());

      if (form.checkValidity())
        setDialog({ show: true, callBackConfirm: handleConfirm });
    },
    [handleConfirm, setDialog]
  );

  const getData = useCallback(async () => {
    try {
      const groupRulesResult = await groupRuleService.getAll();
      setGroupRules(groupRulesResult);
    } catch (error) {
      console.log(`error in AddEditUser - function getData, error:${error}`);
    }
  }, [groupRuleService]);

  useEffect(() => {
    if (user?.Id) {
      setUserAddEdit(user);
    }
    getData();
  }, [getData, user]);

  return (
    <Container>
      <Title backButton> {user?.Id ? 'Editar' : 'Adicionar'} Usuário </Title>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} sm={12} md={6} controlId="validationName">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="Nome"
              defaultValue={userAddEdit.Name}
              onChange={e =>
                setUserAddEdit(prevState => ({
                  ...prevState,
                  Name: e.target.value
                }))
              }
            />
            <small>*campo opcional</small>
          </Form.Group>
          <Form.Group as={Col} sm={12} md={6} controlId="validationEmail">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              type="email"
              name="email"
              required
              placeholder="E-mail"
              defaultValue={userAddEdit.Email}
              onChange={e =>
                setUserAddEdit(prevState => ({
                  ...prevState,
                  Email: e.target.value
                }))
              }
            />
            <Form.Control.Feedback type="invalid">
              Campo Obrigatório
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} sm={12} md={6} controlId="validationStatus">
            <Form.Switch
              type="switch"
              label="Ativo?"
              id="status"
              name="status"
              checked={userAddEdit.Status === enumStatus.Active}
              onChange={e =>
                setUserAddEdit(prevState => ({
                  ...prevState,
                  Status: e.target.checked
                    ? enumStatus.Active
                    : enumStatus.Inactive
                }))
              }
            />
          </Form.Group>
          <Form.Group as={Col} sm={12} md={6} controlId="validationIsAdmin">
            <Form.Switch
              type="switch"
              label="Administrador?"
              id="isAdmin"
              name="isAdmin"
              checked={userAddEdit.IsAdm}
              onChange={e =>
                setUserAddEdit(prevState => ({
                  ...prevState,
                  AdminGroupRules: [] as IGroupRule[],
                  IsAdm: e.target.checked
                }))
              }
            />
          </Form.Group>
        </Row>
        {!userAddEdit.IsAdm && (
          <Row className="mb-3">
            <Form.Group
              as={Col}
              sm={12}
              md={6}
              controlId="validationGroupRules"
            >
              <Form.Label>Grupos</Form.Label>
              <div className="overflow-y">
                {groupRules &&
                  groupRules.map((group, index) => (
                    <Form.Switch
                      key={group.Id}
                      type="switch"
                      label={group.Description}
                      id={group.Id}
                      name={group.Id}
                      value={group.Id}
                      feedback={
                        !userAddEdit.AdminGroupRules?.length && index === 0
                          ? 'Campo Obrigatório'
                          : ''
                      }
                      checked={userAddEdit.AdminGroupRules.some(
                        g => g.Id === group.Id
                      )}
                      feedbackType="invalid"
                      onChange={() => handleGroupsAdd(group.Id)}
                      required={
                        !userAddEdit.IsAdm &&
                        !userAddEdit.AdminGroupRules?.length
                      }
                    />
                  ))}
              </div>
            </Form.Group>
          </Row>
        )}
        <hr />
        <Button variant="primary" type="submit">
          Salvar
        </Button>
      </Form>
    </Container>
  );
};

export default AddEditUser;
