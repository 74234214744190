import { IGroupRule } from 'interfaces/IGroupRule';
import React from 'react';
import { Container } from './styles';

const PopoverBodyGroupRules: React.FC<{
  groupRules: IGroupRule[];
}> = ({ groupRules }) => (
  <Container>
    {groupRules.map(g => (
      <p className="break-word" key={g.Id}>
        {g.Description}
      </p>
    ))}
  </Container>
);

export default PopoverBodyGroupRules;
