import { Card } from 'components/Card';
import { IOrderBillingData } from 'interfaces/IOrder';
import React from 'react';
import { Button } from 'react-bootstrap';
import { CaretDownFill, CaretUpFill } from 'styled-icons/bootstrap';
import TableBillings from '../TableBillings';
import { Container } from './styles';

interface IProps {
  data: IOrderBillingData;
  handleCardShow: (cardName: string) => void;
  cardShow: string;
  cardShowName: string;
  title: string;
}

const CardBillings: React.FC<IProps> = ({
  data,
  handleCardShow,
  cardShow,
  cardShowName,
  title
}) => {
  const dataFiltered: IOrderBillingData = {
    Billings: data.Billings?.filter(x => x.ProductContractUpperId !== null),
    Total: data.Total
  };

  return (
    <Container>
      <Button
        variant="secondary"
        className="btn-block"
        type="button"
        onClick={() => {
          handleCardShow(cardShowName);
        }}
      >
        {cardShow === cardShowName ? (
          <CaretUpFill className="float-left" size="20px" />
        ) : (
          <CaretDownFill className="float-left" size="20px" />
        )}
        {title}{' '}
        <small className="float-right">
          R${' '}
          {data.Total.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </small>
      </Button>
      <Card className="card-small" show={cardShow === cardShowName}>
        <TableBillings data={dataFiltered} />
      </Card>
    </Container>
  );
};

export default CardBillings;
