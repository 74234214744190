import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled(Modal)`
  .modal-body-max-height {
    max-height: 300px;
    overflow-y: auto;
  }
  .alert {
    margin-bottom: 0 !important;
  }
`;
