import { useMsal } from '@azure/msal-react';
import { useMainContext } from 'context/MainContext';
import React, { useCallback } from 'react';
import api from 'services/api';
import * as S from './styles';

const Logout: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  className
}) => {
  const { msalInstance } = useMainContext();
  const { instance } = useMsal();

  const handleLogout = useCallback(async () => {
    api.defaults.headers.common.Authorization = '';
    sessionStorage.clear();
    await instance.logoutRedirect({
      postLogoutRedirectUri: '/login'
    });
    console.log('usuário deslogado com sucesso');
  }, [instance]);

  return (
    <S.Container className={className} onClick={handleLogout}>
      <S.LogoutICon />
      <span>Sair</span>
    </S.Container>
  );
};

export default Logout;
