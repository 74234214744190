import styled from 'styled-components';

export const Container = styled.div``;

export const WrapperContent = styled.div`
  position: absolute;
  top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  /* background-color: var(--white); */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  box-shadow: var(--shadow-standard);
  border-radius: 4px;
  width: 95%;

  margin: 20px 0;
  @media (min-width: 650px) {
    width: 85%;
  }
  > div {
    width: 100%;
    padding: 15px;
    @media (min-width: 650px) {
      padding: 15px 50px;
    }
    > h1 {
      margin-bottom: 1rem;
    }
  }
`;
