import BackButton from 'components/BackButton';
import React from 'react';
import * as S from './styles';

const Title: React.FC<{ backButton?: boolean; className?: string }> = ({
  backButton,
  className,
  children
}) => (
  <>
    {backButton && <BackButton />}
    {className?.includes('h2-title') ? (
      <S.H2 className={className}>{children}</S.H2>
    ) : (
      <S.H1 className={className}>{children}</S.H1>
    )}
  </>
);

export default Title;
