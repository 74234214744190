import { IRule } from 'interfaces/IRule';
import { useCallback, useMemo } from 'react';
import { useFetch } from './useFetch';

export const useRuleService = () => {
  const { callApi } = useFetch();

  const getAll = useCallback(async () => {
    const groups = await callApi<IRule[]>({
      url: 'Rule/GetAll',
      method: 'get',
      prefixMsgError: 'error in useRuleService - getAll:'
    });

    return groups;
  }, [callApi]);

  const get = useCallback(
    async (id: string) => {
      const groupRule = await callApi<IRule>({
        url: `Rule/Get/${id}`,
        method: 'get',
        prefixMsgError: 'error in useRuleService - get:'
      });

      return groupRule;
    },
    [callApi]
  );

  const add = useCallback(
    async (rule: IRule) => {
      await callApi({
        url: 'Rule/Add',
        method: 'post',
        data: rule,
        prefixMsgError: 'error in useRuleService - add:'
      });
    },
    [callApi]
  );

  const update = useCallback(
    async (rule: IRule) => {
      await callApi({
        url: `Rule/Update/${rule.Id}`,
        method: 'put',
        data: rule,
        prefixMsgError: 'error in useRuleService - update:'
      });
    },
    [callApi]
  );

  return useMemo(
    () => ({
      ruleService: { get, getAll, add, update }
    }),
    [get, getAll, add, update]
  );
};
