/* eslint-disable no-param-reassign */
import Allow from 'components/Allow';
import Title from 'components/Title';
import { enumRules } from 'enum/enumRules';
import { IClient } from 'interfaces/IClient';
import { ICompany } from 'interfaces/ICompany';
import { IProductPrice, IProductPriceUpdate } from 'interfaces/IProduct';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useClientService } from 'services/clientService';
import { useCompanyService } from 'services/companyService';
import { useProductPriceService } from 'services/productPriceService';
import ChoiceComponent from './Components/ChoiceComponent';
import FilterTextPriceList from './Components/FilterTextPriceList';
import TablePriceListClient from './Components/TablePriceListClient';
import TablePriceListCompany from './Components/TablePriceListCompany';
import { Container } from './styles';

export const ProductPricelist: React.FC = () => {
  const { companyService } = useCompanyService();
  const { clientService } = useClientService();
  const { productPriceService } = useProductPriceService();

  const [companies, setCompanies] = useState<ICompany[]>([] as ICompany[]);
  const [updateHistory, setUpdateHistory] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);

  const [companyClients, setCompanyClients] = useState<IClient[]>(
    [] as IClient[]
  );
  const [productPriceList, setProductPriceList] = useState<
    IProductPrice[] | null
  >(null);
  const [selectedCompany, setSelectedCompany] = useState<number>(1);
  const [selectedClient, setSelectedClient] = useState<number>(0);

  const getData = useCallback(async () => {
    const { Companies } = await companyService.getAll();
    const { Clients } = await clientService.findClientByCompanyId(
      selectedCompany
    );
    setCompanies(Companies);
    setCompanyClients(Clients);

    if (selectedClient > 0) {
      const { ProductPrices } =
        await productPriceService.findProductPriceByClientId(selectedClient);
      setProductPriceList(ProductPrices);
    } else {
      const { ProductPrices } =
        await productPriceService.findProductPriceByCompanyId(selectedCompany);
      setProductPriceList(ProductPrices);
    }
  }, [
    companyService,
    clientService,
    selectedCompany,
    productPriceService,
    selectedClient
  ]);

  const handleConfirm = useCallback(async () => {
    const updates: IProductPriceUpdate[] = [];
    productPriceList?.map(item =>
      updates.push({
        companyId: item.CompanyId,
        costCompany: item.CostCompany,
        priceCompany: item.PriceCompany,
        productId: item.ProductId,
        priceClient: item.PriceClient,
        costClient: item.CostClient,
        clientId: item.ClientId,
        replacePrice: updateHistory
      })
    );

    try {
      await productPriceService.updateProductPrice(updates);
      getData();
    } catch (error) {
      console.log(
        `error in AddProduct - function handleConfirm, error:${error}`
      );
    }
  }, [updateHistory, productPriceList, getData, productPriceService]);

  const handleSubmit = useCallback(() => {
    setShow(true);
  }, [setShow]);

  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <Container>
      <ChoiceComponent
        show={show}
        setShow={setShow}
        callBackConfirm={handleConfirm}
        choice={updateHistory}
        setChoice={setUpdateHistory}
      />
      <Title>Preços de Produtos</Title>
      {/* SELECTS ESTAO AQUI */}
      <Row>
        <Form.Group as={Col}>
          <Form.Label>Companhia</Form.Label>
          <Form.Select
            id="select-company"
            name="select"
            required
            defaultValue={selectedCompany}
            onChange={a => {
              setSelectedCompany(Number(a.target.value));
              setSelectedClient(0);
            }}
          >
            {companies.map(a => (
              <option key={a.Id} value={a.Id}>
                {a.Name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Cliente</Form.Label>
          <Form.Select
            id="select-client"
            name="select"
            onChange={a => setSelectedClient(Number(a.target.value))}
            defaultValue={0}
          >
            <option selected value={0} label="Selecione" />
            {companyClients.map(a => (
              <option key={a.Id} value={a.Id}>
                {a.Name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>
      <br />
      <FilterTextPriceList
        placeholderInput="Id, Nome, Tipo..."
        nameFieldToFilter="ProductId,ProductName,ProductInternalName,ProductType"
        dataToFilter={productPriceList}
        setDataToFilter={setProductPriceList}
      />
      {selectedCompany > 0 && selectedClient === 0 && (
        <TablePriceListCompany
          company={selectedCompany}
          list={productPriceList}
          setProductPriceList={setProductPriceList}
        />
      )}
      {selectedCompany > 0 && selectedClient > 0 && (
        <TablePriceListClient
          company={selectedCompany}
          list={productPriceList}
          setProductPriceList={setProductPriceList}
        />
      )}
      <br />
      <Row>
        <Allow ruleId={enumRules.ProductPrice.CanWriteProductPrice}>
          <Col>
            <Button id="restore-btn" onClick={() => getData()}>
              Restaurar
            </Button>
            {` `}
            <Button onClick={handleSubmit}>Salvar</Button>
          </Col>
        </Allow>
      </Row>
    </Container>
  );
};
