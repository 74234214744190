import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin:0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    max-height: 100vh;
    height: 100%;
    min-width: fit-content;
    background-color:var(--background);
    color:var(--dark-grey);
  }

  body{
    &.overflow-hidden{
      overflow: hidden !important;
    }
  }

  *, input, span{
    font-size: var(--font-size);

    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    letter-spacing: 0.9px;
  }

  h1, h2, h3, h4{
    color: var(--dark-grey);
    text-align: center;
  }

  h1{
    font-size:calc(var(--font-size)* 1.5);
    font-weight: 900;
  }

  h2{
    font-size: calc(var(--font-size)* 1.25);
    font-weight: 700;
  }

  h3{
    font-size:var(--font-size);
  }

  h4{
    font-size:calc(var(--font-size)* 0.75)
  }

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .btn.btn-primary {
    background-color: var(--primary);
    border-color: var(--primary);
    &:hover{
      opacity:0.8;
    }
    &:focus{
      box-shadow: none;
    }
  }

  .btn.btn-secondary {
    &:focus{
      box-shadow: none;
    }
    background-color: var(--medium-grey);
    border-color: var(--medium-grey);
    &:hover{
      opacity:0.8;
    }
  }

  .btn.btn-outline-primary {
    border-color: var(--primary);
    color: var(--primary);
    &:hover{
      background-color: var(--primary);
      opacity:0.8;
      color: var(--white);
    }
    &:focus{
      box-shadow: none;
    }
  }
  .form-control{
     border-color: var(--light-grey);
     &:focus{
      border-color: var(--focus-input);
      box-shadow: none;
      //color:var(--dark-grey);
    }
    //color:var(--medium-grey);
  }

  .table-thead-fixed > .table-responsive {
    overflow-y: auto;
    max-height: 450px;
    table th {
      position: sticky;
      top: 0;
    }

    table {
      position: relative;
    }
  }

  /* Styles da parte de Tables */
  table {
    border-collapse: separate !important;
    border-spacing: 0 !important;
    th, td{
      vertical-align: middle;
    }

    &.thead-transparent{
      thead {
        th{
            background-color: transparent !important;
            border-bottom:0.1px solid var(--medium-grey) !important;
          }
      }
    }

    thead {
      th{
        border-bottom: 2px solid var(--medium-grey);
        z-index: 1 !important;
        background-color: var(--light-grey2) !important;
        font-weight:700 !important;
      }
    }

    tr.pointer:hover td {
        cursor: pointer ;
        font-weight:bold;
      }
  }

  .table>:not(:first-child) {
    border-top: none;
  }

  .btn-block{
    width:100%;
  }

  .float-left{
    float: left;

  }

  .float-right{
    float: right;
  }

  .float-right-xs{
    float:none;
    @media (min-width: 576px) {
      float: right;
    }
  }

  .background-grey{
    background-color: var(--light-grey2) !important;
  }

  small {
    font-size: calc(var(--font-size) *0.6);
  }

  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .input-group>.form-control:focus,
  .input-group>.form-select:focus {
      z-index: initial !important;
  }

  .form-check-input:checked {
    background-color: var(--primary);
    border-color: var(--primary);
  }

  .sm-margin-bottom{
    margin-bottom: 0.5rem;
  }

  .md-margin-bottom{
    margin-bottom: 1rem;
  }

  .sm-margin-left{
    margin-left: 0.5rem;
  }

  .span-ellipsis {
    display: inline-block;
    width: 105px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .break-word {
    display:block;
    width:150px;
    word-wrap:break-word;
  }

  .overflow-y {
    overflow-y: auto;
    max-height: 150px;
  }

  :root {
    --background: #F5F6FA;
    --primary: #ff4f28;
    --primary-hover: #e34420;
    --white: #fffefe;
    --dark-grey: #2d2d2d;
    --medium-grey: #6a6c72;
    --light-grey: #8181814a;
    --light-grey2: #b4b4b4;
    --light-grey3: #0000000f;
    --light-grey4: #f5f5f5;
    --color-label: var(--medium-grey);
    --color-text: #37393E;
    --color-small: #6a6c72;
    --focus-input: #848484;
    --font-size:18px;
    --color-success: #0f5132;

    @media (min-width: 650px) {
      --font-size:16px;
    }
    --color-shadow: rgba(0, 0, 0, 0.25);
    --shadow-standard: 0px 1px 3px var(--color-shadow);
    /* --gradient-primary: linear-gradient(180deg,#ababab57 0%,#89645c 100%); */
    --gradient-primary: linear-gradient(180deg,#ababab57 0%,#ff4f2882 100%);
  }
`;
