import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import { Erase } from 'styled-icons/entypo';
import { FilterAlt } from 'styled-icons/material';
import { Container } from './styles';

interface Props {
  dataToFilter: any[];
  setDataToFilter: React.Dispatch<React.SetStateAction<any[]>>;
  nameFieldStartDate: string;
  nameFieldEndDate: string;
}
const FilterDate: React.FC<Props> = ({
  dataToFilter,
  nameFieldStartDate,
  nameFieldEndDate,
  setDataToFilter
}) => {
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [data, setData] = useState<any[]>();
  const [dateInvalid, setDateInvalid] = useState<boolean>(false);

  const handleFilterDate = useCallback(() => {
    setDateInvalid(false);
    if (
      endDate !== undefined &&
      startDate !== undefined &&
      startDate.toDate() > endDate.toDate()
    ) {
      setDateInvalid(true);
      return;
    }

    if (data && !endDate && !startDate) {
      setDataToFilter(data);
    } else if (
      endDate !== undefined &&
      startDate !== undefined &&
      startDate.toDate() <= endDate.toDate()
    ) {
      const filtered = data?.filter(
        x =>
          `${x[nameFieldStartDate]}`.substring(0, 10).toDate() >=
            startDate.toDate() &&
          `${x[nameFieldEndDate]}`.substring(0, 10).toDate() <= endDate.toDate()
      );

      if (filtered) setDataToFilter(filtered);
    }
  }, [
    data,
    endDate,
    nameFieldEndDate,
    nameFieldStartDate,
    setDataToFilter,
    startDate
  ]);

  const handleFilterDateClean = useCallback(() => {
    setStartDate('');
    setEndDate('');
    setDateInvalid(false);
    if (data) setDataToFilter(data);
  }, [data, setDataToFilter]);

  useEffect(() => {
    if (!data?.length && dataToFilter.length) {
      const newData = dataToFilter.filter(x => true);
      setData(newData);
    }
  }, [data, dataToFilter]);

  return (
    <Container>
      <Row className="sm-margin-bottom">
        <Col xs={12} lg={8}>
          <InputGroup>
            <FormControl
              type="date"
              value={startDate}
              placeholder="Data Inicial"
              onChange={e => setStartDate(e.currentTarget.value)}
            />
            <FormControl
              type="date"
              value={endDate}
              placeholder="Data Final"
              onChange={e => setEndDate(e.currentTarget.value)}
              onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter') {
                  handleFilterDate();
                }
              }}
            />
            <Button variant="outline-primary" onClick={handleFilterDate}>
              <FilterAlt width="22px" />
            </Button>
            {startDate && endDate && (
              <Button
                variant="outline-secondary"
                onClick={handleFilterDateClean}
              >
                <Erase width="22px" />
              </Button>
            )}
          </InputGroup>
        </Col>
      </Row>
      {dateInvalid && (
        <Row>
          <Col xs={12} lg={8}>
            <p className="text-center alert-danger">
              <small>Data Inválida</small>
            </p>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default FilterDate;
