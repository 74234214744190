import { PhoneDesktopAdd } from '@styled-icons/fluentui-system-regular/PhoneDesktopAdd';
import Allow from 'components/Allow';
import Title from 'components/Title';
import { enumRules } from 'enum/enumRules';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TableProducts from './Components/TableProducts';
import { Container } from './styles';

export const Products: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Title>Produtos</Title>
      <Row>
        <Allow ruleId={enumRules.Product.CanWriteProduct}>
          <Col xs={12} sm={6}>
            <Button
              variant="outline-primary"
              title="Adicionar Produto"
              onClick={() => navigate('addEditProduct')}
            >
              <PhoneDesktopAdd size="20" />
            </Button>
          </Col>
        </Allow>
      </Row>
      <br />
      <TableProducts />
    </Container>
  );
};
