import styled from 'styled-components';

export const H1 = styled.h1`
  text-transform: uppercase;
  border-bottom: 0.1px solid var(--light-grey);
  &.border-top {
    border-top: 0.1px solid var(--light-grey);
  }
`;

export const H2 = styled.h2`
  text-transform: uppercase;
  border-bottom: 0.1px solid var(--light-grey);
  &.border-top {
    border-top: 0.1px solid var(--light-grey);
  }
`;
