/* eslint-disable no-param-reassign */
import { useMainContext } from 'context/MainContext';
import { enumRules } from 'enum/enumRules';
import { IProductPrice } from 'interfaces/IProduct';
import React, { useCallback } from 'react';
import {
  Col,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from 'react-bootstrap';
import { PersonCircle } from 'styled-icons/bootstrap';
import { CorporateFare } from 'styled-icons/material';
import { masks } from 'utils/masks';

import TableTooltips from '../TableTooltips';

type Props = {
  company: number;
  list: IProductPrice[] | null;
  setProductPriceList: React.Dispatch<
    React.SetStateAction<IProductPrice[] | null>
  >;
};

const TablePriceListClient: React.FC<Props> = ({
  list,
  setProductPriceList,
  company
}) => {
  const { setDialog, profile } = useMainContext();
  const productPriceList = list as IProductPrice[];

  // zera Cost e PriceCompany, isto afetara o style de ativo/inativo
  const handlePriceClientField = useCallback(
    (item: IProductPrice, nullPrice: boolean, field: 'cost' | 'price') => {
      setProductPriceList(prevState => {
        const prev = prevState as IProductPrice[];
        prev.forEach(x => {
          if (x.ProductId === item.ProductId)
            if (field === 'cost') x.CostClient = nullPrice ? null : 0;
            else x.PriceClient = nullPrice ? null : 0;
        });
        return [...prev];
      });
    },
    [setProductPriceList]
  );
  const showAlert = useCallback(
    (item: IProductPrice, field: 'cost' | 'price') => {
      const costOrPriceText = field === 'cost' ? 'custo' : 'preço';
      setDialog({
        show: true,
        textBody: `Ao deixar este campo em branco o produto assumirá o ${costOrPriceText} padrão da companhia de origem. Deseja que este produto adote o ${costOrPriceText} padrão da companhia ?`,
        callBackConfirm: () => handlePriceClientField(item, true, field),
        callBackCancel: () => handlePriceClientField(item, false, field)
      });
    },
    [setDialog, handlePriceClientField]
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: IProductPrice,
    field: 'cost' | 'price'
  ) => {
    let value: number | null;
    if (e.target.value.length === 0) {
      showAlert(item, field);
    } else value = Number(e.target.value);

    setProductPriceList(prevState => {
      const prev = prevState as IProductPrice[];
      prev.forEach(x => {
        if (x.ProductId === item.ProductId) {
          if (field === 'cost') x.CostClient = value;
          else x.PriceClient = value;
        }
      });
      return [...prev];
    });
  };

  const returnTooltip = (object: IProductPrice) =>
    object.PriceClient !== null || object.CostClient !== null ? (
      <Tooltip>Preço ou Custo personalizados para cliente.</Tooltip>
    ) : (
      <Tooltip>Preço ou Custo padrões da companhia.</Tooltip>
    );

  const returnIcon = (object: IProductPrice) =>
    object.PriceClient !== null || object.CostClient !== null ? (
      <PersonCircle size={20} cursor="pointer" color="blue" />
    ) : (
      <CorporateFare size={20} cursor="pointer" />
    );

  const brl = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });

  return (
    <div>
      {productPriceList && productPriceList.length > 0 && (
        <Row>
          <Col>
            <div className="table-thead-fixed">
              <Table className="table text-center" responsive>
                <thead style={{ zIndex: 1000 }}>
                  <tr>
                    <th>Id</th>
                    <th>Nome</th>
                    <th>Nome Interno</th>
                    <th>Tipo</th>
                    <th>
                      Custo Base{' '}
                      <TableTooltips description="Valor de Custo do produto da Companhia." />
                    </th>
                    <th>
                      Preço Base{' '}
                      <TableTooltips description="Preço de venda padrão aplicado pela companhia para seus clientes." />
                    </th>
                    <th>
                      Custo Personalizado{' '}
                      <TableTooltips description="Preço de custo para este cliente. Pode conter um valor personalizado ou assumir o custo padrão da Companhia se estiver em branco." />
                    </th>
                    <th>
                      Preço Personalizado{' '}
                      <TableTooltips description="Preço de venda para este cliente. Pode conter um valor personalizado ou assumir o preço padrão da Companhia se estiver em branco." />
                    </th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody id="tablebody">
                  {productPriceList.map((productPrice: IProductPrice) => (
                    <tr
                      key={`${productPrice.ProductId}${productPrice.CompanyId}${productPrice.ClientId}`}
                      style={{
                        color:
                          productPrice.PriceClient !== null ||
                          productPrice.CostClient !== null
                            ? 'blue'
                            : ''
                      }}
                    >
                      <td>{productPrice.ProductId}</td>
                      <td>{productPrice.ProductName}</td>
                      <td>{productPrice.ProductInternalName}</td>
                      <td>{productPrice.ProductType}</td>
                      <td>{brl.format(productPrice.CostCompany as number)}</td>
                      <td>{brl.format(productPrice.PriceCompany as number)}</td>
                      <td>
                        {profile?.Rules?.includes(
                          enumRules.ProductPrice.CanWriteProductPrice
                        ) || profile?.IsAdm ? (
                          <InputGroup>
                            <InputGroup.Text>R$</InputGroup.Text>
                            <FormControl
                              key={`costClient${productPrice.ProductId}${productPrice.CompanyId}`}
                              type="number"
                              inputMode="numeric"
                              onBlur={e => {
                                if (
                                  e.target.value.length === 0 &&
                                  productPrice.CostClient !== null
                                )
                                  showAlert(productPrice, 'cost');
                              }}
                              min="0"
                              step=".01"
                              value={
                                (productPrice.CostClient?.toFixed(
                                  2
                                ) as string) || ''
                              }
                              defaultValue={productPrice.CostClient?.toFixed(2)}
                              onKeyDown={e => {
                                if (
                                  e.key === 'ArrowUp' ||
                                  e.key === 'ArrowDown'
                                )
                                  e.preventDefault();
                              }}
                              onChange={e => {
                                masks.currency(
                                  e as React.FormEvent<HTMLInputElement>
                                );
                                handleChange(
                                  e as React.ChangeEvent<HTMLInputElement>,
                                  productPrice,
                                  'cost'
                                );
                              }}
                            />
                          </InputGroup>
                        ) : (
                          // eslint-disable-next-line react/jsx-no-useless-fragment
                          <>
                            {productPrice.CostClient !== null ? (
                              brl.format(productPrice.CostClient as number)
                            ) : (
                              // eslint-disable-next-line jsx-a11y/heading-has-content
                              <h1 />
                            )}
                          </>
                        )}
                      </td>
                      <td>
                        {profile?.Rules?.includes(
                          enumRules.ProductPrice.CanWriteProductPrice
                        ) || profile?.IsAdm ? (
                          <InputGroup>
                            <InputGroup.Text>R$</InputGroup.Text>
                            <FormControl
                              key={`priceClient${productPrice.ProductId}${productPrice.CompanyId}`}
                              type="number"
                              inputMode="numeric"
                              onBlur={e => {
                                if (
                                  e.target.value.length === 0 &&
                                  productPrice.PriceClient !== null
                                )
                                  showAlert(productPrice, 'price');
                              }}
                              min="0"
                              step=".01"
                              value={
                                (productPrice.PriceClient?.toFixed(
                                  2
                                ) as string) || ''
                              }
                              defaultValue={productPrice.PriceClient?.toFixed(
                                2
                              )}
                              onKeyDown={e => {
                                if (
                                  e.key === 'ArrowUp' ||
                                  e.key === 'ArrowDown'
                                )
                                  e.preventDefault();
                              }}
                              onChange={e => {
                                masks.currency(
                                  e as React.FormEvent<HTMLInputElement>
                                );
                                handleChange(
                                  e as React.ChangeEvent<HTMLInputElement>,
                                  productPrice,
                                  'price'
                                );
                              }}
                            />
                          </InputGroup>
                        ) : (
                          // eslint-disable-next-line react/jsx-no-useless-fragment
                          <>
                            {productPrice.PriceClient !== null ? (
                              brl.format(productPrice.PriceClient as number)
                            ) : (
                              // eslint-disable-next-line jsx-a11y/heading-has-content
                              <h1 />
                            )}
                          </>
                        )}
                      </td>
                      <td>
                        <OverlayTrigger
                          trigger={['click', 'hover']}
                          overlay={returnTooltip(productPrice)}
                        >
                          {returnIcon(productPrice)}
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default TablePriceListClient;
