import { IUser } from 'interfaces/IUser';
import { useCallback, useMemo } from 'react';
import { useFetch } from './useFetch';

export const useUserService = () => {
  const { callApi } = useFetch();

  const getAll = useCallback(async () => {
    const users = await callApi<IUser[]>({
      url: 'User/GetAll',
      method: 'get',
      prefixMsgError: 'error in useUserService - getAll:'
    });

    return users;
  }, [callApi]);

  const get = useCallback(
    async (id: string) => {
      const user = await callApi<IUser>({
        url: `User/Get/${id}`,
        method: 'get',
        prefixMsgError: 'error in useUserService - get:'
      });

      return user;
    },
    [callApi]
  );

  const add = useCallback(
    async (user: IUser) => {
      await callApi({
        url: 'User/Add',
        method: 'post',
        data: user,
        prefixMsgError: 'error in useUserService - add:'
      });
    },
    [callApi]
  );

  const update = useCallback(
    async (user: IUser) => {
      await callApi({
        url: `User/Update/${user.Id}`,
        method: 'put',
        data: user,
        prefixMsgError: 'error in useUserService - update:'
      });
    },
    [callApi]
  );

  return useMemo(
    () => ({
      userService: { get, getAll, add, update }
    }),
    [get, getAll, add, update]
  );
};
