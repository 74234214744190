import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Erase } from 'styled-icons/entypo';
import { FilterAlt } from 'styled-icons/material';
import { Container } from './styles';

interface Props {
  setDataToFilter: React.Dispatch<React.SetStateAction<any[] | null>>;
  placeholderInput: string;
  nameFieldToFilter?: string;
  dataToFilter?: any[] | null;
  callBackApi?: (textSearch: string) => Promise<any[]>;
}

const FilterText: React.FC<Props> = ({
  nameFieldToFilter,
  setDataToFilter,
  callBackApi,
  dataToFilter,
  placeholderInput
}) => {
  const [inputSearch, setInputSearch] = useState<string>('');
  const [filtered, setFiltered] = useState<boolean>(false);
  const [dataOriginal, setDataOriginal] = useState<any[] | null>(null);

  const searchAsync = useCallback(
    async (textSearch: string) => {
      try {
        if (callBackApi) {
          const result = await callBackApi(textSearch);
          setDataToFilter(result);
          setFiltered(!result?.length);
        }
      } catch (error) {
        console.log(`error in Search - function searchAsync, error:${error}`);
      }
    },
    [callBackApi, setDataToFilter]
  );

  const searchHandle = async (textSearch: string) => {
    if (dataOriginal && nameFieldToFilter) {
      if (!textSearch.trim()) {
        setDataToFilter(dataOriginal);
        setFiltered(false);
      } else if (nameFieldToFilter.includes(',')) {
        const bigArray: any[] = [];
        nameFieldToFilter
          .split(',')
          .forEach(a =>
            dataOriginal
              .filter(x =>
                `${x[a]}`
                  .toLowerCase()
                  .trim()
                  .includes(textSearch.trim().toLowerCase())
              )
              .map(e => bigArray.push(e))
          );
        const arrayResult = bigArray.filter((a, b, c) => c.indexOf(a) === b);
        arrayResult.sort((a, b) => a.Id - b.Id);
        setDataToFilter(arrayResult);
        setFiltered(!arrayResult?.length);
      } else {
        const resultData = dataOriginal.filter(x =>
          `${x[nameFieldToFilter]}`
            .toLowerCase()
            .trim()
            .includes(textSearch.trim().toLowerCase())
        );
        setDataToFilter(resultData);
        setFiltered(!resultData?.length);
      }
    }
  };

  const handleFilterDateClean = useCallback(() => {
    if (dataOriginal) {
      setDataToFilter(dataOriginal);
      setInputSearch('');
      setFiltered(false);
    }
  }, [dataOriginal, setDataToFilter]);

  useEffect(() => {
    if (!dataOriginal && dataToFilter) {
      const newData = dataToFilter.filter(x => true);
      setDataOriginal(newData);
    }
  }, [dataOriginal, dataToFilter]);

  return (
    <Container>
      <Row className="sm-margin-bottom">
        <Col xs={12}>
          <InputGroup>
            <Form.Control
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setInputSearch(event.target.value);
                searchHandle(event.target.value);
              }}
              onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter') {
                  if (callBackApi && inputSearch.trim()) {
                    searchAsync(inputSearch);
                  }
                }
              }}
              value={inputSearch}
              placeholder={placeholderInput}
            />
            <Button
              variant="outline-primary"
              onClick={() => {
                if (callBackApi && inputSearch.trim()) {
                  searchAsync(inputSearch);
                } else {
                  searchHandle(inputSearch);
                }
              }}
            >
              <FilterAlt width="24px" />
            </Button>
            {dataToFilter && nameFieldToFilter && inputSearch && (
              <Button
                variant="outline-secondary"
                onClick={handleFilterDateClean}
              >
                <Erase width="22px" />
              </Button>
            )}
          </InputGroup>
        </Col>
      </Row>
      {filtered && (
        <Row>
          <Col xs={12}>
            <br />
            <h3>Nenhum registro retornado</h3>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default FilterText;
